import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [userRole, setUserRole] = useState('');
    const [whitelisted, setWhitelisted] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const user = localStorage.getItem('userId');
        const role = localStorage.getItem('role');
        const isWhiteListed = localStorage.getItem('isWhiteListed') === 'true';
        console.log('User:', user, 'Role:', role, 'Whitelisted:', isWhiteListed);
        if (user && role) {
            setCurrentUser(user);
            setUserRole(role);
            setWhitelisted(isWhiteListed);
        }
        setLoading(false); // Laden abgeschlossen
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Oder eine andere Ladeanzeige
    }

    return (
        <AuthContext.Provider value={{ currentUser, userRole, whitelisted }}>
            {children}
        </AuthContext.Provider>
    );
};