/**
 * Zeigt uns den Status der Seite an.
 * Anzahl der User, Anzahl der Clients, Anzahl der Interpreter, Anzahl der Jobs, Anzahl der Feedbacks
 * Anzahl der offenen Jobs, Anzahl der offenen Feedbacks
 * Anzahl der aktuell eingeloggten User
 */
import React, {useEffect, useState} from "react";
import {createAuthHeader, fetchUrl} from "../../utils/util";
import { FaUsers, FaUserTie, FaGlobe, FaTasks, FaComments, FaClock, FaUserCheck } from 'react-icons/fa';


export function DashboardAdmin() {
    const [overview, setOverview] = useState([])

    useEffect(() => {
        async function fetchOverview() {
            try {
                const url = `${fetchUrl}/api/admin/overview`;
                const response = await fetch(url,
                    {
                        headers: {
                            ...createAuthHeader(),
                        }
                    });

                if (response.ok) {
                    const data = await response.json();
                    setOverview(data);
                } else {
                    throw new Error('Fehler beim Laden der Overview: ' + response.status);
                }
            } catch (error) {
                console.error(error);
            }
        }

        fetchOverview()
    }, []);


    return (
        <div className="container mx-auto mt-8 px-4">
            <h1 className="text-3xl font-bold mb-8 text-center">Allgemeine Übersicht</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                <div className="border p-6 rounded-lg shadow-lg bg-white flex items-center">
                    <FaUsers className="text-4xl text-blue-500 mr-4"/>
                    <div>
                        <h3 className="text-lg font-bold mb-2">Anzahl der User</h3>
                        <p className="text-2xl font-semibold">{overview.users}</p>
                    </div>
                </div>
                <div className="border p-6 rounded-lg shadow-lg bg-white flex items-center">
                    <FaUserTie className="text-4xl text-green-500 mr-4"/>
                    <div>
                        <h3 className="text-lg font-bold mb-2">Anzahl der Clients</h3>
                        <p className="text-2xl font-semibold">{overview.clients}</p>
                    </div>
                </div>
                <div className="border p-6 rounded-lg shadow-lg bg-white flex items-center">
                    <FaGlobe className="text-4xl text-purple-500 mr-4"/>
                    <div>
                        <h3 className="text-lg font-bold mb-2">Anzahl der Interpreter</h3>
                        <p className="text-2xl font-semibold">{overview.interpreters}</p>
                    </div>
                </div>
                <div className="border p-6 rounded-lg shadow-lg bg-white flex items-center">
                    <FaTasks className="text-4xl text-yellow-500 mr-4"/>
                    <div>
                        <h3 className="text-lg font-bold mb-2">Anzahl der Jobs</h3>
                        <p className="text-2xl font-semibold">{overview.jobs}</p>
                    </div>
                </div>
                <div className="border p-6 rounded-lg shadow-lg bg-white flex items-center">
                    <FaClock className="text-4xl text-yellow-500 mr-4"/>
                    <div>
                        <h3 className="text-lg font-bold mb-2">Anzahl der offenen Jobs</h3>
                        <p className="text-2xl font-semibold">{overview.openJobs}</p>
                    </div>
                </div>
                <div className="border p-6 rounded-lg shadow-lg bg-white flex items-center">
                    <FaComments className="text-4xl text-red-500 mr-4"/>
                    <div>
                        <h3 className="text-lg font-bold mb-2">Anzahl der Feedbacks</h3>
                        <p className="text-2xl font-semibold">{overview.feedbacks}</p>
                    </div>
                </div>

                <div className="border p-6 rounded-lg shadow-lg bg-white flex items-center">
                    <FaClock className="text-4xl text-indigo-500 mr-4"/>
                    <div>
                        <h3 className="text-lg font-bold mb-2">Anzahl der offenen Feedbacks</h3>
                        <p className="text-2xl font-semibold">TBU</p>
                    </div>
                </div>
                <div className="border p-6 rounded-lg shadow-lg bg-white flex items-center">
                    <FaUserCheck className="text-4xl text-pink-500 mr-4"/>
                    <div>
                        <h3 className="text-lg font-bold mb-2">Anzahl der aktuell eingeloggten User</h3>
                        <p className="text-2xl font-semibold">TBU</p>
                    </div>
                </div>
            </div>
        </div>
    );

}