import React from "react";
import { motion } from "framer-motion";
import { drawerLogo } from "../../assets";

export const LinksOverlay = () => {
  return (
    <nav className="fixed right-4 top-4 z-[99] h-[calc(100vh_-_32px)] w-[calc(100%_-_32px)] overflow-hidden">
      <Logo />
      <LinksContainer />
    </nav>
  );
};

const LinksContainer = () => {
  return (
    <motion.div className="p-12 pl-4 space-y-4 md:pl-20">
      {LINKS.map((l, idx) => {
        return (
          <NavLink key={l.title} href={l.href} idx={idx}>
            {l.title}
          </NavLink>
        );
      })}
    </motion.div>
  );
};

const NavLink = ({ children, href, idx }) => {
  return (
    <motion.a
      initial={{ opacity: 0, y: -8 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: {
          delay: 0.75 + idx * 0.125,
          duration: 0.5,
          ease: "easeInOut",
        },
      }}
      exit={{ opacity: 0, y: -8 }}
      href={href}
      className="block text-5xl font-semibold text-white transition-colors hover:text-white hover:text-opacity-70 md:text-7xl"
    >
      {children}
    </motion.a>
  );
};

const Logo = () => {
  return (
    <motion.a
      initial={{ opacity: 0, y: -12 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: { delay: 0.5, duration: 0.5, ease: "easeInOut" },
      }}
      exit={{ opacity: 0, y: -12 }}
      href="#"
      className="grid w-20 h-20 transition-colors bg-white place-content-center rounded-br-xl rounded-tl-xl hover:bg-violet-50"
    >
      <img src={drawerLogo} alt="Drawer Logo" className="!w-[70px]" />
    </motion.a>
  );
};

const LINKS = [
  {
    title: "Startseite",
    href: "/",
  },
  {
    title: "Auftraggeber",
    href: "/clients",
  },
  {
    title: "Dolmetscher",
    href: "/interpreters",
  },
  {
    title: "Registrieren",
    href: "/register",
  },
  {
    title: "Anmelden",
    href: "/login",
  },
];
