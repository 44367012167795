import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createAuthHeader, fetchUrl } from "../../utils/util";

export function Feedbacks() {
    const [feedbacks, setFeedbacks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchFeedbacks() {
            try {
                const url = `${fetchUrl}/api/admin/feedbacks`;
                const response = await fetch(url, {
                    headers: {
                        ...createAuthHeader(),
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setFeedbacks(data);
                } else {
                    throw new Error('Fehler beim Laden der Feedbacks: ' + response.status);
                }
            } catch (error) {
                console.error(error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }

        fetchFeedbacks();
    }, []);

    if (loading) {
        return <div>Laden...</div>;
    }
    if (error) {
        return <div>Fehler: {error}</div>;
    }

    return (
        <div className="container mx-auto mt-8 px-4">
            <h1 className="text-2xl font-bold mb-8 text-center">Feedbacks</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {feedbacks.map((feedback, index) => (
                    <div key={index} className="border rounded-lg shadow-lg bg-white flex flex-col h-full">
                        <div className="p-6 flex-grow">
                            <p><strong>User ID:</strong> {feedback.userID}</p>
                            <p><strong>Feedback:</strong> {feedback.feedback}</p>
                            <p><strong>Erstellt am:</strong> {new Date(feedback.createdAt).toLocaleString()}</p>
                            <p><strong>Abgearbeitet:</strong> {feedback.resolved ? 'Ja' : 'Nein'}</p>
                            <p><strong> Issue Link: </strong> {feedback.issueTicketLink}</p>
                            <p><strong> Feedback ID: </strong> {feedback.feedbackID}</p>
                        </div>
                        <div className="mt-auto">
                            <button
                                onClick={() => navigate(`/dashboard-admin/nutzer/${feedback.userID}`)}
                                className="w-full bg-duoColours-2 text-duoColours-1 font-bold py-2 rounded-b-lg"
                            >
                                Zum Nutzer
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
