import {useRef, useState} from "react";
import {createAuthHeader, fetchUrl} from "../utils/util";
import Alert from "../Tools/Alert";

export default function Feedback() {
    const [feedback, setFeedback] = useState({
        userId: localStorage.getItem('userId'), feedback: ''
    });
    const [error, setError] = useState(''); // Zustand für Fehlermeldungen
    const [alertType, setAlertType] = useState('success'); // Zustand für den Typ des Alerts
    const [alertMessage, setAlertMessage] = useState(''); // Zustand für die Nachricht des Alerts
    const alertRef = useRef(); // Referenz für den Alert
    const [isLoading, setIsLoading] = useState(false); // Zustand für das Laden

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!feedback.feedback.trim()) { // Prüft, ob das Feedback leer oder nur Leerzeichen ist
            setError('Bitte geben Sie Feedback ein, bevor Sie es absenden.'); // Setze eine Fehlermeldung
            setIsLoading(false); // Setzt das Laden zurück
            return; // Unterbricht die Funktion, um das Senden zu verhindern
        }

        setError(''); // Löscht Fehlermeldungen, wenn alles in Ordnung ist
        let role = localStorage.getItem('role');
        try {
            const response = await fetch(fetchUrl + '/api/feedback/' + (role.toLowerCase()), { // Sendet das Feedback an den Server
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...createAuthHeader()
                },
                body: JSON.stringify(feedback) // Stellt sicher, dass das Objekt korrekt gesendet wird
            });

            if (response.ok) {
                console.log('Feedback erfolgreich gesendet');
                setAlertMessage('Feedback erfolgreich gesendet'); // Setzt die Erfolgsmeldung
                setAlertType('info'); // Setzt den Typ des Alerts auf Erfolg
                setFeedback({userId: localStorage.getItem('userId'), feedback: ''}); // Löscht das Feedbackfeld
                alertRef.current?.triggerAlert();
            } else {
                console.error('Feedbackfehler', response);
                setError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.'); // Setze eine Fehlermeldung
            }
        } catch (error) {
            console.error('Feedbackfehler', error);
            setError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.'); // Setze eine Fehlermeldung
        }
        setIsLoading(false); // Setzt das Laden zurück
    };

    return (
        <div className="container mx-auto p-4 md:p-10">
            <div className="bg-white shadow-lg rounded-lg p-6">
                <h1 className="text-3xl font-bold text-gray-700 mb-6 text-center">Ihre Meinung ist uns wichtig</h1>
                <p className="text-gray-600 text-lg mb-6">
                    Wir möchten, dass Sie sich bei uns wohlfühlen und uns gerne weiterempfehlen. Deshalb sind wir immer an Ihrem Feedback interessiert.
                    <br /><br />
                    Teilen Sie uns mit, was Ihnen gefällt und wo wir uns noch verbessern können.
                    <br /><br />
                    Wir freuen uns auf Ihre Anregungen.
                </p>
                <form onSubmit={handleSubmit}>
                    <div className="mt-8">
                        <textarea
                            className="p-2 border border-gray-300 rounded w-full focus:ring focus:ring-duoColours-2 focus:outline-none"
                            placeholder="Ihr Feedback"
                            rows="4"
                            maxLength={499}
                            value={feedback.feedback}
                            onChange={(e) => setFeedback({ ...feedback, feedback: e.target.value })}
                        />
                        <button
                            type="submit"
                            className="w-full mt-4 bg-homeTextGradient text-white font-bold py-2 px-4 rounded transition-colors duration-300"
                            disabled={isLoading}
                        >
                            {isLoading ?
                                (
                                    <div className="flex items-center justify-center text-white">
                                        <div className="spinnerButton"></div>
                                    </div>
                                ) : (
                                    'Feedback absenden'
                                )}
                        </button>
                        {error &&
                            <p className="text-red-500 text-sm mt-2">{error}</p>} {/* Zeigt Fehlermeldung an, wenn vorhanden */}
                        <Alert message={alertMessage} type={alertType} reference={alertRef} classname="w-full pt-2 z-50"/>
                    </div>
                </form>
            </div>
        </div>
    );
}