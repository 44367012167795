import {useState, useRef, useEffect} from 'react';
import Peer from 'simple-peer';
import {fetchUrl, createAuthHeader, DEBUG_MODE, USE_ICE_CANDIDATES} from "../../utils/util";
import {useParams} from 'react-router-dom';
import placeholderImage from "../../assets/placeholder-video.jpg";

const usePeerConnection = (selectedCamera, selectedMicrophone, addLog) => {
    const {jobId} = useParams();
    const [localStream, setLocalStream] = useState(null);
    const [remoteStream, setRemoteStream] = useState(null);
    const [connectionStatus, setConnectionStatus] = useState('disconnected');
    const [callStatus, setCallStatus] = useState('idle');
    const [isAudioEnabled, setIsAudioEnabled] = useState(true);
    const [isVideoEnabled, setIsVideoEnabled] = useState(true);
    const [isPolling, setIsPolling] = useState(false);
    const peerRef = useRef(null);
    const streamRef = useRef(null);
    const role = localStorage.getItem('role');
    const [errorMessage, setErrorMessage] = useState('');
    const [reconnectionAttempts, setReconnectionAttempts] = useState(0);

    const handleMediaError = async (error) => {
        let message;
        switch (error.name) {
            case 'NotFoundError':
                message = 'No camera or microphone found. Please connect them and try again.';
                break;
            case 'NotReadableError':
                message = 'Camera or microphone is already in use. Please close other applications.';
                break;
            case 'OverconstrainedError':
                message = 'Selected device is not accessible. Please select a different device.';
                break;
            case 'NotAllowedError':
                message = 'Permission denied. Please allow access to your camera and microphone.';
                break;
            default:
                message = 'An unexpected error occurred: ' + error.message;
        }
        setErrorMessage(message);
        setCallStatus('error');
        if (DEBUG_MODE) addLog('Media Error', message);

        // Attempt to proceed with audio only and placeholder video
        if (error.name !== 'NotAllowedError' && error.name !== 'NotFoundError') {
            try {
                const audioConstraint = selectedMicrophone ? { deviceId: { exact: selectedMicrophone } } : true;
                const audioStream = await navigator.mediaDevices.getUserMedia({
                    video: false,
                    audio: audioConstraint,
                });

                // Create a placeholder video stream
                const placeholderVideoStream = await createPlaceholderVideoStream();

                // Combine audio and placeholder video streams
                const combinedStream = new MediaStream([
                    ...placeholderVideoStream.getVideoTracks(),
                    ...audioStream.getAudioTracks(),
                ]);

                setLocalStream(combinedStream);
                streamRef.current = combinedStream;

                monitorTracks(); // Monitor tracks for disconnection

                const initiator = role === 'CLIENT';
                const peer = initiatePeerConnection(initiator, combinedStream);
                peerRef.current = peer;

                setCallStatus('connecting');

                if (DEBUG_MODE) addLog('Proceeding with audio and placeholder video', '');
            } catch (audioError) {
                // If we can't access audio devices, display an error
                message = 'Unable to access audio devices. Please check your microphone.';
                setErrorMessage(message);
                if (DEBUG_MODE) addLog('Media Error', message);
                setCallStatus('error');
            }
        }
    };

    // Monitor media tracks for disconnection
    const monitorTracks = () => {
        streamRef.current.getTracks().forEach((track) => {
            track.onended = () => {
                if (track.kind === 'video') {
                    setIsVideoEnabled(false);
                } else if (track.kind === 'audio') {
                    setIsAudioEnabled(false);
                }
                if (DEBUG_MODE) addLog(`Media track ended: ${track.kind}`, '');
                // Optionally, attempt to reconnect or prompt the user
            };
        });
    };

    const createPlaceholderVideoStream = () => {
        return new Promise((resolve) => {
            const canvas = document.createElement('canvas');
            canvas.width = 640;
            canvas.height = 480;
            const context = canvas.getContext('2d');

            const image = new Image();
            image.src = placeholderImage; // Use the imported image

            const fps = 15; // Frame rate to keep the video track active

            image.onload = () => {
                const draw = () => {
                    context.drawImage(image, 0, 0, canvas.width, canvas.height);
                    setTimeout(draw, 1000 / fps);
                };
                draw();

                const stream = canvas.captureStream(fps);
                resolve(stream);
            };

            image.onerror = () => {
                context.fillStyle = '#333';
                context.fillRect(0, 0, canvas.width, canvas.height);

                const stream = canvas.captureStream(fps);
                resolve(stream);
            };
        });
    };


    // Helper function to get role-based URL
    const getRoleBasedUrl = (endpoint) => {
        return role === 'CLIENT' ? `/api/webrtc/client/${endpoint}` : `/api/webrtc/interpreter/${endpoint}`;
    };

    // Initialize peer connection
    // Initialize peer connection
    const initiatePeerConnection = (initiator, stream) => {
        if (peerRef.current) {
            peerRef.current.destroy();
            peerRef.current = null;
        }

        const peer = new Peer({
            initiator,
            trickle: USE_ICE_CANDIDATES,
            stream: stream,
            config: {
                iceServers: [
                    { urls: 'stun:stun.l.google.com:19302' },
                    // Add TURN servers here if needed
                ],
            },
        });

        peer.on('signal', async (data) => {
            await sendSignalData(data);
        });

        peer.on('connect', () => {
            setConnectionStatus('connected');
            setCallStatus('connected');
            if (DEBUG_MODE) addLog('Peer connection established', '');
            setReconnectionAttempts(0); // Reset reconnection attempts
        });

        peer.on('stream', (remoteStream) => {
            setRemoteStream(remoteStream);
            if (DEBUG_MODE) addLog('Remote stream received', 'Stream received');
        });

        peer.on('error', (err) => {
            if (DEBUG_MODE) addLog('Peer Connection Error', err.message);
            handlePeerError(err);
        });

        peer.on('close', () => {
            if (DEBUG_MODE) addLog('Peer connection closed', '');
            handlePeerDisconnection();
        });

        return peer;
    };

    // Handle peer connection errors
    const handlePeerError = (error) => {
        setConnectionStatus('disconnected');
        if (DEBUG_MODE) addLog('Handling peer error', error.message);

        // Attempt reconnection logic
        if (reconnectionAttempts < 3) {
            setReconnectionAttempts(reconnectionAttempts + 1);
            setTimeout(() => {
                if (DEBUG_MODE) addLog('Attempting to reconnect...', '');
                startCall();
            }, 2000 * reconnectionAttempts); // Exponential backoff
        } else {
            setErrorMessage('Unable to establish connection. Please check your network and try again.');
            setCallStatus('error');
        }
    };

    // Start the call
    const startCall = async () => {
        setCallStatus('connecting');
        try {
            setIsPolling(true);
            if (role === 'CLIENT') {
                await clearSDPAndICE();
            }

            // Prepare media constraints
            const videoConstraint = selectedCamera ? {deviceId: {exact: selectedCamera}} : true;
            const audioConstraint = selectedMicrophone ? {deviceId: {exact: selectedMicrophone}} : true;

            // Request user media
            let stream;
            try {
                // Request user media
                stream = await navigator.mediaDevices.getUserMedia({
                    video: videoConstraint,
                    audio: audioConstraint,
                });
            } catch (error) {
                if (error.name === 'NotFoundError' && !videoConstraint) {
                    // No camera, use placeholder video
                    const placeholderVideoStream = await createPlaceholderVideoStream();
                    const audioStream = await navigator.mediaDevices.getUserMedia({
                        video: false,
                        audio: audioConstraint,
                    });

                    // Combine audio and placeholder video streams
                    stream = new MediaStream([
                        ...placeholderVideoStream.getVideoTracks(),
                        ...audioStream.getAudioTracks(),
                    ]);

                    setIsVideoEnabled(false);
                    if (DEBUG_MODE) addLog('Using placeholder video stream', '');
                } else {
                    throw error;
                }
            }

            setLocalStream(stream);
            streamRef.current = stream;

            const initiator = role === 'CLIENT';
            const peer = initiatePeerConnection(initiator, stream);
            peerRef.current = peer;

            addLog && addLog(`Peer initialized as ${initiator ? 'initiator' : 'receiver'}`, '');
        } catch (error) {
            // Handle media device error
            handleMediaError(error);
        }
    };

    // End the call
    const endCall = async () => {
        setCallStatus('ending');
        setIsPolling(false);
        await handlePeerDisconnection();
        if (DEBUG_MODE) addLog('Call ended', '');
    };

    // Handle peer disconnection
    const handlePeerDisconnection = async () => {
        setConnectionStatus('disconnected');
        setCallStatus('idle');
        await clearSDPAndICE();
        if (peerRef.current) {
            peerRef.current.destroy();
            peerRef.current = null;
        }
        if (streamRef.current) {
            streamRef.current.getTracks().forEach((track) => {
                track.stop();
                track.onended = null;
            });
            streamRef.current = null;
        }
        setLocalStream(null);
        setRemoteStream(null);
        if (DEBUG_MODE) addLog('Cleaned up peer connection and streams', '');
    };

    // Send signaling data to backend
    const sendSignalData = async (data) => {
        const signalUrl = getRoleBasedUrl('signal');
        try {
            const response = await fetch(`${fetchUrl}${signalUrl}`, {
                method: 'POST',
                headers: {
                    ...createAuthHeader(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    jobId: parseInt(jobId),
                    userId: parseInt(localStorage.getItem('userId')),
                }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            if (DEBUG_MODE) addLog('Signal data sent to backend', data);
        } catch (error) {
            if (DEBUG_MODE) addLog('Error sending signal data to backend', error.message);
        }
    };

    // Polling for signals
    useEffect(() => {
        let isMounted = true;

        const pollSignals = async () => {
            if (DEBUG_MODE) addLog('Started polling for signals', '');
            const signalUrl = getRoleBasedUrl(`signal/${jobId}`);
            while (isMounted && isPolling && connectionStatus !== 'connected') {
                try {
                    const response = await fetch(`${fetchUrl}${signalUrl}`, {
                        method: 'GET',
                        headers: createAuthHeader(),
                    });

                    if (response.status === 200) {
                        const signalData = await response.json();
                        if (signalData.length > 0) {
                            if (DEBUG_MODE) addLog('Signal data fetched', signalData);
                            const peer = peerRef.current;
                            if (peer) {
                                signalData.forEach((data) => {
                                    peer.signal(data);
                                });
                            }
                        }
                    }
                } catch (error) {
                    if (DEBUG_MODE) addLog('Error polling for signals', error.message);
                }
                await new Promise((resolve) => setTimeout(resolve, 3000)); // Adjusted polling interval
            }
        };

        if (isPolling && connectionStatus !== 'connected') {
            pollSignals();
        }

        return () => {
            isMounted = false;
        };
    }, [isPolling, connectionStatus]);

    // Clear SDP and ICE data
    const clearSDPAndICE = async () => {
        const clearUrl = getRoleBasedUrl('end-call');
        try {
            const response = await fetch(`${fetchUrl}${clearUrl}`, {
                method: 'POST',
                headers: {
                    ...createAuthHeader(),
                },
                body: JSON.stringify({
                    jobId: parseInt(jobId),
                    userId: parseInt(localStorage.getItem('userId')),
                }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            if (DEBUG_MODE) addLog('SDP and ICE data cleared', '');
        } catch (error) {
            if (DEBUG_MODE) addLog('Error clearing SDP and ICE data', error.message);
        }
    };

    // Toggle audio
    const toggleAudio = () => {
        const audioTrack = streamRef.current?.getAudioTracks()[0];
        if (audioTrack) {
            audioTrack.enabled = !audioTrack.enabled;
            setIsAudioEnabled(audioTrack.enabled);
        }
    };

    // Toggle video
    // Toggle video
    const toggleVideo = async () => {
        const videoTrack = streamRef.current?.getVideoTracks()[0];

        if (videoTrack && videoTrack.label === '') {
            // Currently using placeholder video, attempt to access real video device
            try {
                const videoConstraint = selectedCamera ? { deviceId: { exact: selectedCamera } } : true;
                const newStream = await navigator.mediaDevices.getUserMedia({
                    video: videoConstraint,
                    audio: false,
                });

                const newVideoTrack = newStream.getVideoTracks()[0];
                const senderVideo = peerRef.current?.getSenders().find((s) => s.track.kind === 'video');

                if (senderVideo && newVideoTrack) {
                    senderVideo.replaceTrack(newVideoTrack);
                    streamRef.current.removeTrack(videoTrack);
                    streamRef.current.addTrack(newVideoTrack);
                    setLocalStream(streamRef.current);
                    setIsVideoEnabled(true);
                }
            } catch (error) {
                if (DEBUG_MODE) addLog('Error accessing video device', error.message);
                setIsVideoEnabled(false);
                setErrorMessage('Unable to access video device.');
            }
        } else if (videoTrack) {
            // Toggle video track enabled state
            videoTrack.enabled = !videoTrack.enabled;
            setIsVideoEnabled(videoTrack.enabled);
        } else {
            // No video track available, attempt to access video device
            try {
                const videoConstraint = selectedCamera ? { deviceId: { exact: selectedCamera } } : true;
                const newStream = await navigator.mediaDevices.getUserMedia({
                    video: videoConstraint,
                    audio: false,
                });

                const newVideoTrack = newStream.getVideoTracks()[0];
                streamRef.current.addTrack(newVideoTrack);

                const senderVideo = peerRef.current?.getSenders().find((s) => s.track.kind === 'video');
                if (senderVideo) {
                    senderVideo.replaceTrack(newVideoTrack);
                } else {
                    peerRef.current.addTrack(newVideoTrack, streamRef.current);
                }

                setLocalStream(streamRef.current);
                setIsVideoEnabled(true);
            } catch (error) {
                if (DEBUG_MODE) addLog('Error accessing video device', error.message);
                setIsVideoEnabled(false);
                setErrorMessage('Unable to access video device.');
            }
        }
    };

    // Handle device changes
    useEffect(() => {
        const changeDevice = async () => {
            if (!streamRef.current) return;

            try {
                const videoConstraints = selectedCamera ? { deviceId: { exact: selectedCamera } } : false;
                const audioConstraints = selectedMicrophone ? { deviceId: { exact: selectedMicrophone } } : false;

                const constraints = {};
                if (videoConstraints) constraints.video = videoConstraints;
                if (audioConstraints) constraints.audio = audioConstraints;

                if (!constraints.video && !constraints.audio) {
                    throw new Error('No media devices selected');
                }

                const newStream = await navigator.mediaDevices.getUserMedia(constraints);

                const newVideoTrack = newStream.getVideoTracks()[0];
                const newAudioTrack = newStream.getAudioTracks()[0];

                if (peerRef.current) {
                    // Replace video track
                    if (newVideoTrack) {
                        const oldVideoTrack = streamRef.current.getVideoTracks()[0];
                        peerRef.current.replaceTrack(oldVideoTrack, newVideoTrack, streamRef.current);
                        streamRef.current.removeTrack(oldVideoTrack);
                        streamRef.current.addTrack(newVideoTrack);
                    }
                    // Replace audio track
                    if (newAudioTrack) {
                        const oldAudioTrack = streamRef.current.getAudioTracks()[0];
                        peerRef.current.replaceTrack(oldAudioTrack, newAudioTrack, streamRef.current);
                        streamRef.current.removeTrack(oldAudioTrack);
                        streamRef.current.addTrack(newAudioTrack);
                    }
                } else {
                    // No peer connection yet, just update the local stream
                    streamRef.current = newStream;
                }

                setLocalStream(streamRef.current);

                monitorTracks(); // Monitor new tracks

                if (DEBUG_MODE) addLog('Media devices changed successfully', '');
            } catch (error) {
                if (DEBUG_MODE) addLog('Error changing device', error.message);
                setErrorMessage('Unable to change media devices.');
            }
        };

        changeDevice();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCamera, selectedMicrophone]);
    ;

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            setIsPolling(false);
            if (peerRef.current) {
                peerRef.current.destroy();
                peerRef.current = null;
            }
            if (streamRef.current) {
                streamRef.current.getTracks().forEach((track) => {
                    track.stop();
                    track.onended = null;
                });
                streamRef.current = null;
            }
            if (DEBUG_MODE) addLog('Component unmounted and resources cleaned up', '');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        localStream,
        remoteStream,
        startCall,
        endCall,
        toggleAudio,
        toggleVideo,
        isAudioEnabled,
        isVideoEnabled,
        connectionStatus,
        callStatus,
        errorMessage,
    };
};

export default usePeerConnection;
