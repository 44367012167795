import React from "react";
import { communicationIcon, meetingIcon, translatorIcon } from "../../assets";

const HowItWorks = () => {
  return (
    <div className="mt-20 sm:mt-32 lg:mt-40 scroll-mt-8" id="work">
      <h2 className="text-4xl text-center text-black sm:text-6xl font-pp">
        So funktioniert es
      </h2>

      <div className="flex flex-col items-center justify-center gap-5 mt-8 xl:gap-0 xl:flex-row">
        <div className="flex flex-col gap-3 px-3 py-12 sm:px-6 w-full lg:w-[700px] bg-worksGradient1 rounded-xl ">
          <img
            src={meetingIcon}
            alt="Translator Icon"
            className="block mx-auto max-md:w-[70px]"
          />

          <h1 className="text-3xl text-center text-transparent sm:text-4xl font-pp bg-homeTextGradient bg-clip-text">
            Aufträge erstellen
          </h1>

          <p className="text-lg text-black ">
            • Geben Sie alle wichtigen Informationen zum Auftrag an
          </p>
          <p className="text-lg text-black ">
            • Wählen Sie spezifische Anforderungen oder Fachgebiete
          </p>
          <p className="text-lg text-black ">
            • Bestätigen Sie den Auftrag und erhalten Benachrichtigungen
          </p>
        </div>

        <div className="relative z-[1] flex flex-col gap-3 px-3 py-12 sm:px-6 xl:mt-40 xl:-mx-4 bg-worksGradient2 rounded-xl ">
          <img
            src={translatorIcon}
            alt="Translator Icon"
            className="block mx-auto max-md:w-[80px]"
          />

          <h1 className="text-3xl text-center text-transparent sm:text-4xl font-pp bg-homeTextGradient bg-clip-text">
            Dolmetscher wählen
          </h1>

          <p className="text-lg text-transparent bg-clip-text bg-homeTextGradient">
            • Sie können gezielt den Dolmetscher auswählen
          </p>
          <p className="text-lg text-transparent bg-clip-text bg-homeTextGradient">
            • Passende Dolmetscher erhalten den Auftrag
          </p>
          <p className="text-lg text-transparent bg-clip-text bg-homeTextGradient">
            • Dolmetscher haben die Möglichkeit, den Auftrag anzunehmen oder
            abzulehnen.
          </p>
        </div>

        <div className="flex flex-col gap-3 px-3 py-8 sm:px-6 w-full lg:w-[700px] bg-worksGradient1 rounded-xl ">
          <img
            src={communicationIcon}
            alt="Translator Icon"
            className="block mx-auto max-md:w-[230px]"
          />

          <h1 className="text-3xl text-center text-transparent font-pp bg-homeTextGradient bg-clip-text">
            Benachrichtigt werden
          </h1>

          <p className="text-lg text-black ">
            • Erhalten Sie umgehend eine Bestätigung per E-Mail, sobald ein
            Dolmetscher den Auftrag annimmt.
          </p>
          <p className="text-lg text-black ">
            • Bleiben Sie durch Status-Updates über den gesamten Prozess
            informiert.
          </p>
          <p className="text-lg text-black ">
            • Erinnerungen vor dem Termin garantieren eine reibungslose
            Abwicklung.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
