import React from 'react';
import { videoOffIcon, videoOnIcon, micOffIcon, micOnIcon } from '../../assets';
import './Controls.css';

const Controls = ({
                      toggleAudio,
                      toggleVideo,
                      isAudioEnabled,
                      isVideoEnabled,
                      endCall,
                      callStatus,
                  }) => {
    return (
        <div className="controls-container">
            <div className="controls">
                <button
                    onClick={toggleVideo}
                    className={`control-button ${
                        isVideoEnabled ? 'control-button-on' : 'control-button-off'
                    }`}
                >
                    <img src={isVideoEnabled ? videoOnIcon : videoOffIcon} alt="Toggle Video" />
                </button>
                <button
                    onClick={toggleAudio}
                    className={`control-button ${
                        isAudioEnabled ? 'control-button-on' : 'control-button-off'
                    }`}
                >
                    <img src={isAudioEnabled ? micOnIcon : micOffIcon} alt="Toggle Audio" />
                </button>
                {callStatus === 'connected' && (
                    <button
                        onClick={endCall}
                        className="end-call-button"
                    >
                        Anruf beenden
                    </button>
                )}
            </div>
        </div>
    );
};

export default Controls;
