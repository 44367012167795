import React from "react";
import {Outlet} from "react-router-dom";
import Sidebar from "./Sidebar";
import HeaderDashboard from "./HeaderDashboard";

export default function LayoutDashboard() {
    return (
        <div className='bg-neutral-100 h-screen w-screen overflow-hidden flex flex-row'>
            <Sidebar/>
            <div className="flex flex-col flex-1">
                {/*<HeaderDashboard/>*/} {/* Fancy header maybe later implement... */}
                <div className="flex-1 p-4 min-h-0 overflow-auto">
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}