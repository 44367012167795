import React, {useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import {LinksOverlay} from "../../componenten/shared/drawer";
import Navbar from "../../componenten/shared/navbar";


function Datenschutz() {
    return (
        <>
            <div className="">
                <Navbar/>
                <div className="mt-16 md:mt-24 w-full max-w-4xl mx-auto p-6 ">
                    <h1 className="text-3xl font-bold text-blue-900 mb-6">Datenschutz</h1>

                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Datenschutzerklärung</h2>
                        <p className="text-lg text-gray-700">Text</p>
                    </div>

                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">1. Begriffsbestimmungen</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>

                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">2. Name und Anschrift des für die
                            Verarbeitung Verantwortlichen</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">3. Cookies</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">4. Erfassung von allgemeinen Daten und
                            Informationen</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">5. Routinemäßige Löschung und Sperrung
                            von personenbezogenen Daten</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">6. Rechte der betroffenen Person</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">7. Datenschutz bei Bewerbungen und im
                            Bewerbungsverfahren</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">8. Datenschutzbestimmungen zu Einsatz
                            und Verwendung von Google Analytics (mit Anonymisierungsfunktion)</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">9. Plugins und Tools</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">10. Rechtsgrundlage der
                            Verarbeitung</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">11. Berechtigte Interessen an der
                            Verarbeitung, die von dem Verantwortlichen oder einem Dritten verfolgt werden</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">12. Dauer, für die die
                            personenbezogenen Daten gespeichert werden</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">13. Gesetzliche oder vertragliche
                            Vorschriften zur Bereitstellung der personenbezogenen Daten; Erforderlichkeit für den
                            Vertragsabschluss; Verpflichtung der betroffenen Person, die personenbezogenen Daten
                            bereitzustellen; mögliche Folgen der Nichtbereitstellung</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">14. Datenschutzbeauftragter</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">15. Bestehen einer automatisierten
                            Entscheidungsfindung</h2>
                        <p className="text-lg text-gray-700">
                            text
                        </p>
                    </div>
                </div>
            </div>
            </>

            );
            }

            export default Datenschutz;
