import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {createAuthHeader, fetchUrl, formatDate, useFilter} from "../../utils/util";

export function AuftraegeAdmin() {
    const [jobs, setJobs] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchJobs() {
            try {
                const url = `${fetchUrl}/api/admin/jobs`;
                const response = await fetch(url,
                    {
                        headers: {
                            ...createAuthHeader(),
                        }
                    });

                if (response.ok) {
                    const data = await response.json();
                    setJobs(data);
                } else {
                    throw new Error('Fehler beim Laden der Jobs: ' + response.status);
                }
            } catch (error) {
                console.error(error);
            }
        }


        fetchJobs()
    }, []);

    const filterFunctions = {
        creator: (job, value) => job.auftraggeber.toLowerCase().includes(value.toLowerCase()),
        date: (job, value) => job.date.startsWith(value),
        noInterpreter: (job, value) => value ? job.interpreterId === null : true
    };

    const { filters, handleFilterChange, filteredItems: filteredJobs } = useFilter(jobs, filterFunctions);

    const renderJobDetails = (job) => {
        return (
            <div key={job.id} className="border rounded-lg shadow-lg bg-white flex flex-col h-full">
                <div className="p-6 flex-grow">
                    <h3 className="text-xl font-bold mb-4 text-center">Erstellt von [{job.auftraggeber}] - ID [{job.userId}]</h3>
                    <p><strong>Termin am:</strong> {formatDate(new Date(job.date))} Uhr</p>
                    <p><strong>Dauer:</strong> {job.duration} Minuten</p>
                    <p><strong>Sprache:</strong> {job.language}</p>
                    <p><strong>Einsatzgebiet:</strong> {job.industry}</p>

                    {job.jobType === 'vorort' && (
                        <>
                            <p><strong>Stadt:</strong> {job.locationDTO?.city || 'N/A'}</p>
                            <p><strong>Adresse:</strong> {job.locationDTO?.address || 'N/A'}</p>
                            <p><strong>Raum:</strong> {job.locationDTO?.room || 'N/A'}</p>
                        </>
                    )}

                    {job.jobType === 'video' && (
                        <p><strong>Video Link:</strong> {job.meetinLink ? <a href={job.meetinLink} target="_blank" rel="noopener noreferrer">Zum Video Call</a> : 'Kein Link verfügbar'}</p>
                    )}

                    <p><strong>Zusätzliche Informationen:</strong> {job.additionalInfo}</p>
                    <p><strong>Status:</strong> {job.interpreterId === null ? "Offen" : "Akzeptiert"}</p>
                    <p><strong>Dolmetscher:</strong> {job.assignedInterpreter || "Nicht zugewiesen"}</p>
                </div>
                <button
                    onClick={() => navigate(`/dashboard-admin/auftraege/${job.id}`)}
                    className="w-full bg-duoColours-2 text-duoColours-1 font-bold py-2 px-4 rounded-b-lg"
                >
                    Job einsehen
                </button>
            </div>
        );
    };

    return (
        <div className="container mx-auto mt-8 px-4">
            <h1 className="text-2xl font-bold mb-8 text-center">Alle Aufträge</h1>
            <div className="mb-8 flex flex-col md:flex-row items-center justify-center gap-2 md:gap-4">
                <input
                    type="text"
                    name="creator"
                    placeholder="Ersteller"
                    value={filters.creator || ''}
                    onChange={handleFilterChange}
                    className="border p-2 rounded w-full md:w-auto"
                />
                <input
                    type="date"
                    name="date"
                    value={filters.date || ''}
                    onChange={handleFilterChange}
                    className="border p-2 rounded w-full md:w-auto"
                />
                <label className="flex items-center">
                    <input
                        type="checkbox"
                        name="noInterpreter"
                        checked={filters.noInterpreter || false}
                        onChange={handleFilterChange}
                        className="mr-2"
                    />
                    Keine Dolmetscher zugewiesen
                </label>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {filteredJobs.map((job) => renderJobDetails(job))}
            </div>
        </div>
    );

}