import React, {useState} from "react";
import {logo} from "../../assets";
import {HiOutlineLogout, HiMenu} from "react-icons/hi";
import {
    DASHBOARD_SIDEBAR_BOTTOM_LINKS, DASHBOARD_SIDEBAR_LINKS_A,
    DASHBOARD_SIDEBAR_LINKS_C,
    DASHBOARD_SIDEBAR_LINKS_I,DASHBOARD_SIDEBAR_PLANNED_LINKS_C,
    DASHBOARD_SIDEBAR_PLANNED_LINKS_I
} from "./navigation";
import {Link, useLocation, useNavigate} from "react-router-dom";

export default function Sidebar() {

    const linkClass =
        'flex items-center gap-2 px-3 py-2 rounded-sm text-base transition-colors duration-300 font-semibold';
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate(); // Hook, um die Navigation zu handhaben
    const role = localStorage.getItem('role');
    let links = [];
    let plannedLinks = [];

    if (role === 'INTERPRETER') {
        links = DASHBOARD_SIDEBAR_LINKS_I;
        plannedLinks = DASHBOARD_SIDEBAR_PLANNED_LINKS_I;
    } else if (role === 'CLIENT') {
        links = DASHBOARD_SIDEBAR_LINKS_C;
        plannedLinks = DASHBOARD_SIDEBAR_PLANNED_LINKS_C;
    } else if (role === 'ADMIN') {
        links = DASHBOARD_SIDEBAR_LINKS_A;
        plannedLinks = []; // Falls Sie geplante Funktionen für Admin haben, fügen Sie diese hinzu
    }

    const handleLogout = () => {
        // localStorage bereinigen
        localStorage.removeItem('authToken');
        localStorage.removeItem('role');
        localStorage.removeItem('userId');
        localStorage.removeItem("isWhiteListed");
        navigate('/');
        console.log('Logout');
    };

    const location = useLocation();

    // Schließt die Sidebar beim Klicken auf einen Link
    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    return (
        <div className="relative min-h-screen md:flex z-10">
            {/* Mobile Menu Button */}
            <button
                onClick={() => setSidebarOpen(oldValue => !oldValue)}
                className="text-4xl p-3 absolute left-0 z-50 md:hidden"
                style={{
                    color: isSidebarOpen ? 'white' : 'black',
                    position: 'fixed'
                }} // Farbwechsel basierend auf dem Zustand der Sidebar
            >
                <HiMenu/>
            </button>

            {/* Sidebar Navigation */}
            <div
                className={`fixed inset-y-0 left-0 transform ${
                    isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
                } w-60 bg-sidebarGradient p-3 transition duration-300 ease-in-out md:relative md:translate-x-0 md:z-auto md:block `}
                style={{paddingTop: isSidebarOpen ? '4rem' : '1rem'}} // Abstand anpassen, wenn Sidebar offen ist
            >
                <div className="flex flex-col h-full">
                    <div className="text-white text-xl font-bold mt-4 mb-2 text-center">
                        Funktionen
                    </div>
                    {/* Inhalt der Sidebar */}
                    <div className="flex flex-col flex-grow">
                        {/* Navigationslinks */}
                        <div className="pt-2 pb-2 flex flex-1 flex-col gap-0.5 border-t border-white">
                            {links.map((item) => (
                                <Link
                                    key={item.key}
                                    to={item.path}
                                    className={`${linkClass} hover:no-underline hover:bg-white hover:text-duoColours-2 ${
                                        location.pathname === item.path ? 'bg-white text-black' : 'text-white'
                                    }`}
                                    onClick={closeSidebar} // Sidebar beim Klicken schließen
                                >
                                    <span className="text-xl">{item.icon}</span>
                                    {item.label}
                                </Link>
                            ))}
                        </div>

                        {/* Geplante Funktionen */}
                        {plannedLinks.length > 0 && (
                            <>
                                <div className="text-white text-xl font-bold mt-4 text-center">
                                    Geplante Funktionen
                                </div>
                                <span className="ml-auto text-sm italic">(Bald verfügbar)</span>
                                <div className="pt-2 pb-2 flex flex-col gap-0.5 border-t border-white">
                                    {plannedLinks.map((item) => (
                                        <div
                                            key={item.key}
                                            className={`${linkClass} cursor-not-allowed text-gray-400 flex items-center gap-2`}
                                        >
                                            <span className="text-xl">{item.icon}</span>
                                            {item.label}

                                        </div>
                                    ))}
                                </div>
                            </>
                        )}

                        {/* Logout und untere Links */}
                        <div className="flex flex-col gap-0.5 pt-2 border-t border-white">
                            {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((item) => (
                                <Link
                                    key={item.key}
                                    to={item.path}
                                    className={`${linkClass} hover:no-underline hover:bg-white hover:text-duoColours-2 ${
                                        location.pathname === item.path ? 'bg-white text-black' : 'text-white'
                                    }`}
                                    onClick={closeSidebar} // Sidebar beim Klicken schließen
                                >
                                    <span className="text-xl">{item.icon}</span>
                                    {item.label}
                                </Link>
                            ))}

                            <button
                                onClick={handleLogout}
                                className={`${linkClass} cursor-pointer text-red-500 hover:bg-red-500 hover:text-duoColours-2 flex items-center gap-2`}
                            >
                                <HiOutlineLogout className="text-xl"/>
                                Ausloggen
                            </button>
                        </div>
                    </div>

                    {/* Logo unten platzieren */}
                    <div className="container pt-6">
                        <a href="/">
                            <img src={logo} alt="Logo" className="w-[200px]"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );

}