import {
  featuresImg1,
  featuresImg2,
  featuresImg3,
  featuresImg4,
  pointIcon1,
  pointIcon2,
  pointIcon3,
  serveBellIcon,
  serveBgCardIcon1,
  serveCrosshairIcon,
  serveUsersIcon,
  useImg1,
  useImg10,
  useImg11,
  useImg2,
  useImg4,
  useImg5,
  useImg6,
  useImg7,
  useImg8,
  useImg9,
} from "../../assets";

export const useData = [
  {
    id: 1,
    img: useImg1,
    header: "Treffen Sie eine Vereinbarung mit uns",
    text: "Setzen Sie sich mit unserem Team in Verbindung, um die Bedingungen zu besprechen und eine Vereinbarung über die Vermittlung von Dolmetschern über unsere Plattform zu treffen.",
  },
  {
    id: 2,
    img: useImg2,
    header: "Einfaches Erstellen und Bearbeiten von Aufträgen",
    text: "Richten Sie Ihre Übersetzungsaufträge schnell ein, indem Sie Sprachen, Fachgebiete und Fristen angeben. Bearbeiten Sie Auftragsdetails jederzeit, um sie an Ihre sich ändernden Anforderungen anzupassen.",
  },
  {
    id: 3,
    img: useImg2,
    header: "Wählen Sie spezifische Übersetzer für Ihre Aufträge",
    text: "Wählen Sie aus einem Pool professioneller Übersetzer auf der Grundlage ihrer Fachkenntnisse, Bewertungen und Rezensionen den für Ihr Projekt am besten geeigneten aus.",
  },
  {
    id: 4,
    img: useImg4,
    header: "Erhalten Sie Echtzeit-Benachrichtigungen",
    text: "Bleiben Sie mit Sofortbenachrichtigungen über Auftragsaktualisierungen, die Verfügbarkeit von Übersetzern und den Fortschritt Ihrer Projekte auf dem Laufenden.",
  },
  {
    id: 5,
    img: useImg5,
    header: "Kollegen zur Zusammenarbeit einladen",
    text: "Fügen Sie der Plattform ganz einfach Teammitglieder hinzu, um gemeinsam an Projekten zu arbeiten, Feedback auszutauschen und Aufgaben gemeinsam zu verwalten.",
  },
  {
    id: 6,
    img: useImg6,
    header: "Zukunftsfähig mit KI-Übersetzung",
    text: "Nutzen Sie fortschrittliche KI-Übersetzungstools, um Ihre Arbeitsabläufe weiter zu optimieren und die Übersetzungskosten zu senken.",
  },
];

export const useDataTwo = [
  {
    id: 1,
    img: useImg7,
    header: "Kontaktieren Sie uns zur Verifizierung",
    text: "Setzen Sie sich mit unserem Team in Verbindung, um Ihre Qualifikationen zu überprüfen und sicherzustellen, dass Sie die erforderlichen Standards erfüllen, um als Dolmetscher auf unserer Plattform zu arbeiten.",
  },
  {
    id: 2,
    img: useImg8,
    header: "Erstellen Sie Ihr Profil",
    text: "Sobald Sie verifiziert sind, erstellen Sie ein detailliertes Profil, in dem Sie Ihre Fähigkeiten, Erfahrungen und Fachgebiete hervorheben. Achten Sie darauf, alle erforderlichen Felder auszufüllen, um Kunden zu gewinnen.",
  },
  {
    id: 3,
    img: useImg9,
    header: "Job Benachrichtigungen erhalten",
    text: "Lassen Sie sich sofort über neue Stellenausschreibungen benachrichtigen, die Ihren Fähigkeiten und Ihrer Verfügbarkeit entsprechen. Reagieren Sie schnell, um Ihre Chancen auf eine Anstellung zu erhöhen.",
  },
  {
    id: 4,
    img: useImg10,
    header: "Für Jobs bewerben",
    text: "Stöbern Sie in den verfügbaren Stellenangeboten und bewerben Sie sich auf die Stellen, die Ihren Qualifikationen entsprechen. Nutzen Sie das Prinzip „Wer zuerst kommt, mahlt zuerst“, um sich die besten Chancen zu sichern.",
  },
  {
    id: 5,
    img: useImg11,
    header: "Lernen Sie weiter und lassen Sie sich zertifizieren",
    text: "Nehmen Sie an Schulungen teil und erwerben Sie Zertifizierungen, um Ihr Profil zu verbessern. Bleiben Sie wettbewerbsfähig und erhöhen Sie Ihre Jobchancen, indem Sie Ihre Fähigkeiten kontinuierlich verbessern.",
  },
];

export const serveCardData = [
  {
    id: 1,
    textIcon: "📝",
    header: "Aufträge schnell und einfach erstellen",
    text: "Erstellen und verwalten Sie Ihre Dolmetscheraufträge unkompliziert mit wenigen Klicks und passen Sie Details flexibel an.",
    BgIcon: serveBgCardIcon1,
  },
  {
    id: 2,
    textIcon: "🎯",
    header: "Passenden Dolmetscher auswählen",
    text: "Wählen Sie aus qualifizierten Dolmetschern, basierend auf Sprache, Fachgebiet und Verfügbarkeit, den besten für Ihren Auftrag aus.",
    BgIcon: serveCrosshairIcon,
  },
  {
    id: 3,
    textIcon: "🔔",
    header: "Sofortige Benachrichtigungen erhalten",
    text: "Bleiben Sie immer informiert – von der Auftragserstellung bis zur Bestätigung des Dolmetschers, alles in Echtzeit.",
    BgIcon: serveBellIcon,
  },
  {
    id: 4,
    textIcon: "👥",
    header: "Teammitglieder einladen",
    text: "Teilen Sie Projekte und verwalten Sie Aufträge gemeinsam im Team, um stets den Überblick zu behalten.",
    BgIcon: serveUsersIcon,
  },
];

export const serveCardDataTwo = [
  {
    id: 1,
    textIcon: "📝",
    header: "Neue Aufträge leicht finden",
    text: "Erhalten Sie automatische Benachrichtigungen über Aufträge, die zu Ihren Sprachkenntnissen und Ihrem Zeitplan passen.",
    BgIcon: serveBgCardIcon1,
  },
  {
    id: 2,
    textIcon: "🎯",
    header: "Freie Wahl der Aufträge",
    text: "Wählen Sie die Aufträge aus, die zu Ihnen passen – wann und wo Sie möchten, mit voller Kontrolle über Ihre Verfügbarkeit.",
    BgIcon: serveCrosshairIcon,
  },
  {
    id: 3,
    textIcon: "🔔",
    header: "Kontinuierliches Lernen",
    text: "Nutzen Sie die Möglichkeit, durch regelmäßige Einsätze in verschiedenen Bereichen Ihre Sprachfähigkeiten weiterzuentwickeln.",
    BgIcon: serveBellIcon,
  },
  {
    id: 4,
    textIcon: "👥",
    header: "Teil eines verlässlichen Netzwerks werden",
    text: "Profitieren Sie von langfristigen Partnerschaften mit renommierten Auftraggebern in unterschiedlichen Branchen.",
    BgIcon: serveUsersIcon,
  },
];

export const featuresData = [
  {
    id: 1,
    header: `Qualifizierte \nDolmetscher`,
    img: featuresImg1,
    textArray: [
      {
        textHeader: "Zertifizierte Dolmetscher",
        textImg: pointIcon1,
        text: "Alle unsere Dolmetscher sind geprüft und erfüllen hohe Standards, um exzellente Sprachdienstleistungen anzubieten.",
      },
      {
        textHeader: "Fachgebiet Orientierte Expertise",
        textImg: pointIcon2,
        text: "Egal ob Medizin, Recht oder Behörden – wir bieten Dolmetscher, die sich in Ihrem Fachgebiet bestens auskennen.",
      },
      {
        textHeader: "Schnelle Einsatzbereitschaft",
        textImg: pointIcon3,
        text: "Unsere Dolmetscher stehen kurzfristig zur Verfügung, um sicherzustellen, dass Ihre Termine reibungslos und ohne Verzögerung verlaufen.",
      },
    ],
  },
  {
    id: 2,
    header: `Vertrauensvolle \nPartnerschaften`,
    img: featuresImg2,
    textArray: [
      {
        textHeader: "Langfristige Kooperationen",
        textImg: pointIcon1,
        text: "Unsere Plattform baut vertrauensvolle und dauerhafte Partnerschaften mit Institutionen auf, um kontinuierliche Dolmetschleistungen zu gewährleisten.",
      },
      {
        textHeader: "Maßgeschneiderte Verträge",
        textImg: pointIcon2,
        text: "Wir bieten flexible Vertragsmodelle, die speziell auf die Anforderungen und Bedürfnisse jeder Institution abgestimmt sind.",
      },
      {
        textHeader: "Ständige Qualitätskontrollen",
        textImg: pointIcon3,
        text: "Durch regelmäßige Überprüfungen und Feedbackschleifen sichern wir die Qualität unserer Dolmetschdienste.",
      },
    ],
  },
  {
    id: 3,
    header: `Einfache \nTerminplanung `,
    img: featuresImg3,
    textArray: [
      {
        textHeader: "Intuitives System",
        textImg: pointIcon1,
        text: "Mit unserem benutzerfreundlichen System können Dolmetscher schnell und effizient für jedes Projekt gebucht werden",
      },
      {
        textHeader: "Automatische Benachrichtigungen",
        textImg: pointIcon2,
        text: "Bleiben Sie jederzeit informiert durch automatische Updates bei jeder Änderung Ihres Auftrags.",
      },
      {
        textHeader: "Flexible Anpassung von Terminen",
        textImg: pointIcon3,
        text: "Unsere Plattform ermöglicht es, Termine unkompliziert an die individuellen Bedürfnisse der Auftraggeber anzupassen.",
      },
    ],
  },
  {
    id: 4,
    header: `Schnelle Verfügbarkeit`,
    img: featuresImg4,
    textArray: [
      {
        textHeader: "Dolmetscher online Verfügbar",
        textImg: pointIcon1,
        text: "Unsere qualifizierten Dolmetscher stehen online für kurzfristige und dringende Einsätze zur Verfügung.",
      },
      {
        textHeader: "Notfallservice für dringende Termine",
        textImg: pointIcon2,
        text: "Nutzen Sie unseren schnellen Notfallservice, um sofort einen Dolmetscher für akute Situationen zu finden.",
      },
      {
        textHeader: "Überregionale Einsätze",
        textImg: pointIcon3,
        text: "Wir bieten überregionale Dolmetschdienste an, um sicherzustellen, dass auch in anderen Städten jederzeit ein Dolmetscher verfügbar ist.",
      },
    ],
  },
];
