import React from 'react';
import localPlaceholder from '../../assets/useImg8.png';
import remotePlaceholder from '../../assets/useImg7.png';
import Controls from './Controls';
// TODO: Bei Tab Schließen oder wechseln den nutzer Warnen das der call abbricht "Bist du sicher das du den Anruf beenden möchtest" irgndwie so
// TODO: Nachdem man auf "Anruf beeenden" drückt, kommt dann unten irgendwie wielange man telefoniert hat Dauer oder so
const VideoDisplay = ({
                          localStream,
                          remoteStream,
                          callStatus,
                          startCall,
                          connectionStatus,
                          errorMessage,
                          toggleAudio,
                          toggleVideo,
                          isAudioEnabled,
                          isVideoEnabled,
                          endCall,
                      }) => {
    const localVideoRef = React.useRef();
    const remoteVideoRef = React.useRef();

    React.useEffect(() => {
        if (localVideoRef.current) {
            localVideoRef.current.srcObject = localStream;
        }
    }, [localStream]);

    React.useEffect(() => {
        if (remoteVideoRef.current) {
            remoteVideoRef.current.srcObject = remoteStream;
        }
    }, [remoteStream]);

    return (
        <div className="video-container relative w-full h-[90vh] flex justify-center items-center bg-black rounded-lg overflow-hidden shadow-lg">
            {remoteStream && remoteStream.getVideoTracks().length > 0 ? (
                <video
                    ref={remoteVideoRef}
                    className="w-[90%] h-[90%] object-contain"
                    autoPlay
                    playsInline
                />
            ) : (
                <div className="w-[90%] h-[90%] flex items-center justify-center">
                    <img
                        src={remotePlaceholder}
                        alt="Remote Placeholder"
                        className="w-1/2 h-1/2 object-contain"
                    />
                </div>
            )}

            {localStream && localStream.getVideoTracks().length > 0 ? (
                <video
                    ref={localVideoRef}
                    className="absolute bottom-4 right-4 w-1/4 h-1/4 sm:w-1/6 sm:h-1/6 object-cover border-2 border-white rounded-lg"
                    autoPlay
                    playsInline
                    muted
                />
            ) : (
                <div className="absolute bottom-4 right-4 w-1/4 h-1/4 sm:w-1/6 sm:h-1/6 bg-gray-700 flex items-center justify-center border-2 border-white rounded-lg">
                    <img
                        src={localPlaceholder}
                        alt="Local Placeholder"
                        className="w-full h-full object-contain"
                    />
                </div>
            )}

            {/* Controls Overlay */}
            {callStatus !== 'idle' && (
                <div className="absolute bottom-0 left-0 w-full p-4 bg-black bg-opacity-50 flex justify-center items-center space-x-4">
                    <Controls
                        toggleAudio={toggleAudio}
                        toggleVideo={toggleVideo}
                        isAudioEnabled={isAudioEnabled}
                        isVideoEnabled={isVideoEnabled}
                        endCall={endCall}
                        callStatus={callStatus}
                    />
                </div>
            )}

            {/* Overlays for call status */}
            {callStatus === 'idle' && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <button
                        onClick={startCall}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105 text-xl"
                    >
                        Anruf starten
                    </button>
                </div>
            )}

            {callStatus === 'connecting' && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div className="text-white text-3xl">Verbinde...</div>
                </div>
            )}

            {callStatus === 'error' && (
                <div className="absolute inset-0 flex flex-col items-center justify-center bg-red-800 bg-opacity-75 p-4">
                    <div className="text-white text-2xl mb-4">
                        {errorMessage || 'Error connecting.'}
                    </div>
                    <button
                        onClick={startCall}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Erneut versuchen
                    </button>
                    <button
                        onClick={() => window.location.reload()}
                        className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded mt-2"
                    >
                        Seite neu laden
                    </button>
                </div>
            )}
        </div>
    );
};


export default VideoDisplay;
