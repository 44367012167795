// VorOrtAuftragForm.jsx
import React, {useEffect, useState} from 'react';
import {createAuthHeader, fetchUrl, formOptions} from "../../utils/util";
import {useNavigate} from "react-router-dom";
import {de} from "date-fns/locale";
import {startOfTomorrow} from "date-fns";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function VorOrtAuftragForm() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [jobError, setJobError] = useState('');
    const [confirmChecked, setConfirmChecked] = useState(false);
    const [interpreters, setInterpreters] = useState([]);
    const [selectSpecificInterpreter, setSelectSpecificInterpreter] = useState(false);

    const [job, setJob] = useState({
        language: '',
        locationDTO: {city: '', address: '', room: ''},
        date: '',
        duration: '',
        industry: '',
        createdAt: new Date(),
        interpreterId: null, // Setzen Sie auf null, da beim Erstellen noch kein Dolmetscher zugewiesen ist
        suggestedInterpreterId: -1,
        additionalInfo: '',
        userId: localStorage.getItem('userId'),
        jobType: 'vorort'
    });


    useEffect(() => {
        if (job.language) {
            const fetchInterpreters = async () => {
                try {
                    const response = await fetch(`${fetchUrl}/api/users/interpreters?language=${job.language}&userId=${job.userId}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            ...createAuthHeader()
                        }
                    });
                    const data = await response.json();
                    setInterpreters(data);
                } catch (error) {
                    console.error('Fehler beim Abrufen der Dolmetscher:', error);
                }
            };

            fetchInterpreters();
        }
    }, [job.language, job.userId]);


    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!confirmChecked) {
            setJobError('Bitte bestätigen Sie die Richtigkeit der Daten, bevor Sie fortfahren.');
            setIsLoading(false);
            return;
        }

        try {
            const response = await fetch(fetchUrl + '/api/jobs/client/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...createAuthHeader()
                },
                body: JSON.stringify(job)
            });

            if (response.ok) {
                navigate('/dashboard-client/auftraege-anzeigen');
            } else {
                console.error('Fehler bei der Auftragserstellung ', response.status);
                setJobError('Auftragserstellung fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.');
            }
        } catch (error) {
            console.error('Fehler bei der Auftragstellung', error);
            setJobError('Auftragserstellung fehlgeschlagen. Bitte versuchen Sie es später noch einmal.');
        }
        setIsLoading(false);
    };

    return (
        <form id="jobForm" onSubmit={handleSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow-md">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label htmlFor="language" className="block text-sm font-medium text-gray-700">Sprache</label>
                    <select
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        value={job.language}
                        onChange={(e) => setJob({...job, language: e.target.value})}
                        required={true}
                        id={"languageInput"}
                    >
                        <option value="">Sprache auswählen</option>
                        {formOptions.languages.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="industry" className="block text-sm font-medium text-gray-700">Einsatzbranche</label>
                    <select
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        value={job.industry}
                        onChange={(e) => setJob({...job, industry: e.target.value})}
                        id={"industryInput"}
                        required={true}
                    >
                        <option value="">Einsatzbranche auswählen</option>
                        {formOptions.industries.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="city" className="block text-sm font-medium text-gray-700">Stadt</label>
                    <input
                        type="text"
                        value={job.locationDTO.city}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        onChange={(e) => setJob({
                            ...job,
                            locationDTO: {...job.locationDTO, city: e.target.value}
                        })}
                        placeholder="Stadt"
                        required={true}
                        id={"cityInput"}
                    />
                </div>
                <div>
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700">Adresse</label>
                    <input
                        type="text"
                        value={job.locationDTO.address}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        onChange={(e) => setJob({
                            ...job,
                            locationDTO: {...job.locationDTO, address: e.target.value}
                        })}
                        placeholder="Adresse"
                        required={true}
                        id={"addressInput"}
                    />
                </div>
                <div>
                    <label htmlFor="room" className="block text-sm font-medium text-gray-700">Raum</label>
                    <input
                        type="text"
                        value={job.locationDTO.room}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        onChange={(e) => setJob({
                            ...job,
                            locationDTO: {...job.locationDTO, room: e.target.value}
                        })}
                        placeholder="Raum"
                        id={"roomInput"}
                    />
                </div>
                <div>
                    <label htmlFor="date" className="block text-sm font-medium text-gray-700">Datum und Uhrzeit</label>
                    <ReactDatePicker
                        selected={job.date ? new Date(job.date) : null}
                        onChange={(date) => setJob({...job, date})}
                        locale={de}
                        placeholderText=" Datum und Uhrzeit auswählen"
                        showTimeSelect
                        timeCaption="Uhrzeit"
                        timeIntervals={15}
                        dateFormat="Pp"
                        minDate={startOfTomorrow()}
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        wrapperClassName="w-full"
                        calendarClassName="react-datepicker-custom"
                        id={"dateInput"}
                        required={true}
                    />
                </div>
                <div>
                    <label htmlFor="duration" className="block text-sm font-medium text-gray-700">Dauer
                        (Minuten)</label>
                    <select
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        value={job.duration}
                        onChange={(e) => setJob({...job, duration: e.target.value})}
                        required={true}
                        id={"durationInput"}
                    >
                        <option value="">Termindauer in Minuten</option>
                        {formOptions.durations.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div>
                <label htmlFor="additionalInfo" className="block text-sm font-medium text-gray-700">Zusätzliche
                    Informationen</label>
                <textarea
                    id="additionalInfoForm"
                    name="additionalInfo"
                    value={job.additionalInfo}
                    onChange={(e) => setJob({...job, additionalInfo: e.target.value})}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
            </div>

            {/* Spezifischen Dolmetscher wählen Abschnitt */}
            <div className="p-4 bg-gray-50 rounded-lg shadow-inner space-y-4">
                <label className="block text-sm font-medium text-gray-700 pb-2">Spezifischen Dolmetscher wählen?</label>
                <div className="flex items-center space-x-4">
                    <label className="inline-flex items-center">
                        <input
                            type="radio"
                            name="selectInterpreter"
                            value="yes"
                            checked={selectSpecificInterpreter}
                            onChange={() => setSelectSpecificInterpreter(true)}
                            disabled={!job.language}
                            className="form-radio text-blue-600"
                            id={"selectInterpreterFormYes"}
                        />
                        <span className="ml-2">Ja</span>
                    </label>
                    <label className="inline-flex items-center">
                        <input
                            type="radio"
                            name="selectInterpreter"
                            value="no"
                            checked={!selectSpecificInterpreter}
                            onChange={() => {
                                setSelectSpecificInterpreter(false);
                                setJob({
                                    ...job,
                                    suggestedInterpreterId: -1,
                                });
                            }}
                            className="form-radio text-blue-600"
                            id={"selectInterpreterFormNo"}
                        />
                        <span className="ml-2">Nein</span>
                    </label>
                </div>

                {selectSpecificInterpreter && (
                    <select
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        value={job.suggestedInterpreterId || ''}
                        onChange={(e) => {
                            const selectedInterpreter = interpreters.find(interpreter => interpreter.id === Number(e.target.value));
                            setJob({
                                ...job,
                                suggestedInterpreterId: e.target.value || null,
                                interpreter: selectedInterpreter || null
                            });
                        }}
                        id={"chooseInterpreterForm"}
                    >
                        <option value="">Dolmetscher auswählen</option>
                        {interpreters.map((interpreter) => (
                            <option key={interpreter.id} value={interpreter.id}>
                                {interpreter.firstName} {interpreter.lastName}
                            </option>
                        ))}
                    </select>
                )}
            </div>


            <div className="w-full p-4 bg-gray-50 rounded-lg shadow-inner mt-4">
                <label className="inline-flex items-center">
                    <input
                        type="checkbox"
                        className="form-checkbox text-blue-600"
                        checked={confirmChecked}
                        onChange={(e) => setConfirmChecked(e.target.checked)}
                        id={"confirmForm"}
                    />
                    <span className="ml-2">Ich bestätige hiermit die Daten des Auftrags</span>
                </label>
            </div>
            {jobError && (
                <p className="text-red-600 font-semibold">{jobError}</p>
            )}

            <button
                type="submit"
                className="w-full mt-6 bg-homeTextGradient text-white font-bold py-2 px-4 rounded shadow-md hover:bg-blue-700"
                disabled={isLoading}
                id={"submitForm"}
            >
                {isLoading ?
                    (
                        <div className="flex items-center justify-center text-white">
                            <div className="spinnerButton"></div>
                        </div>
                    ) : (
                        'Auftrag erstellen'
                    )}
            </button>
        </form>
    );
}


export default VorOrtAuftragForm;
