import React, { useState, useEffect  } from 'react';
import VideoDisplay from './VideoDisplay';
import DebugLog from './DebugLog';
import useMediaDevices from './useMediaDevices';
import usePeerConnection from './usePeerConnection';
import PreCallTest from './PreCallTest';
import { DEBUG_MODE, setDEBUG_MODE } from '../../utils/util';

const MeetingPage = () => {
    const [log, setLog] = useState([]);
    const [isPrepared, setIsPrepared] = useState(false);
    const [isDebugMode, setIsDebugMode] = useState(false);

    useEffect(() => {
        const toggleDebugMode = (event) => {
            // Check for a specific key combination (Ctrl + Shift + D)
            if (event.key === 'F10') {
                setIsDebugMode((prevMode) => !prevMode);
                setDEBUG_MODE(!DEBUG_MODE);
                console.log(`DEBUG_MODE is now ${!isDebugMode}`);
            }
        };

        window.addEventListener('keydown', toggleDebugMode);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('keydown', toggleDebugMode);
        };
    }, [isDebugMode]);


    const proceedToCall = () => {
        setIsPrepared(true);
    };

    const {
        videoDevices,
        audioDevices,
        selectedCamera,
        setSelectedCamera,
        selectedMicrophone,
        setSelectedMicrophone,
        deviceError,
    } = useMediaDevices();

    const addLog = (title, details) => {
        if (isDebugMode) {
            const timestamp = new Date().toLocaleTimeString();
            setLog((prevLog) => [...prevLog, { timestamp, title, details, expanded: false }]);
        }
    };

    const {
        localStream,
        remoteStream,
        startCall,
        endCall,
        toggleAudio,
        toggleVideo,
        isAudioEnabled,
        isVideoEnabled,
        connectionStatus,
        callStatus,
        errorMessage,
    } = usePeerConnection(selectedCamera, selectedMicrophone, addLog);

    const toggleLogEntry = (index) => {
        setLog((log) =>
            log.map((entry, i) => (i === index ? { ...entry, expanded: !entry.expanded } : entry))
        );
    };

    return (
        <>
            {!isPrepared ? (
                <div className="flex-grow flex flex-col items-center justify-center bg-white ">
                    <PreCallTest
                        videoDevices={videoDevices}
                        audioDevices={audioDevices}
                        selectedCamera={selectedCamera}
                        setSelectedCamera={setSelectedCamera}
                        selectedMicrophone={selectedMicrophone}
                        setSelectedMicrophone={setSelectedMicrophone}
                        proceedToCall={proceedToCall}
                    />
                </div>
            ) : (
                <>
                    <div className={`flex-grow flex flex-col p-4 ${isDebugMode ? 'w-full lg:w-3/4' : 'w-full'} overflow-hidden`}>
                        <VideoDisplay
                            localStream={localStream}
                            remoteStream={remoteStream}
                            callStatus={callStatus}
                            startCall={startCall}
                            connectionStatus={connectionStatus}
                            errorMessage={errorMessage || deviceError}
                            toggleAudio={toggleAudio}
                            toggleVideo={toggleVideo}
                            isAudioEnabled={isAudioEnabled}
                            isVideoEnabled={isVideoEnabled}
                            endCall={endCall}
                        />
                    </div>

                    {isDebugMode && <DebugLog log={log} toggleLogEntry={toggleLogEntry} />}
                </>
            )}
        </>
    );
};

export default MeetingPage;
