import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {createAuthHeader, fetchUrl, formatDate} from "../../utils/util";

export const JobIDAdmin = () => {
    const {jobID} = useParams();
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchJob() {
            try {
                const url = `${fetchUrl}/api/admin/job/${jobID}`;
                const response = await fetch(url, {
                    headers: {
                        ...createAuthHeader(),
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setJob(data);
                } else {
                    throw new Error('Fehler beim Laden des Jobs: ' + response.status);
                }
            } catch (error) {
                console.error(error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }

        fetchJob();
    }, [jobID]);

    if (loading) {

        return <div>Laden...</div>;
    }
    if (error) {

        return <div>Fehler: {error}</div>;
    }
    if (!job) {

        return <div>Kein Job gefunden</div>;
    }

    return (
        <div className="container mx-auto mt-8 px-4">
            <h1 className="text-2xl font-bold mb-8 text-center"> Job Details</h1>
            <div className="border p-6 rounded-lg shadow-lg bg-white">
                <h3 className="text-xl font-bold mb-4 text-center">Erstellt von [{job.auftraggeber}] - ID
                    [{job.userId}]</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <p><strong>Termin am: </strong>{formatDate(new Date(job.date))} Uhr</p>
                    <p><strong>Dauer: </strong>{job.duration} Minuten</p>
                    <p><strong>Sprache:</strong> {job.language}</p>
                    <p><strong>Stadt: </strong>{job.locationDTO.city}</p>
                    <p><strong>Info: </strong> {job.additionalInfo}</p>
                    <p><strong>Status:</strong> {job.interpreterId === null ? "Offen" : "Akzeptiert"}</p>
                    <p>
                        <strong>Dolmetscher: </strong> {job.assignedInterpreter === null ? "Leer" : job.assignedInterpreter}
                    </p>
                    <p><strong>Einsatzgebiet: </strong> {job.industry}</p>
                </div>
                <div className="flex justify-center mt-8">
                    <button onClick={() => navigate(-1)}
                            className="bg-duoColours-2 text-duoColours-1 font-bold py-2 px-6 rounded">
                        Zurück
                    </button>
                </div>
            </div>

        </div>
    );
}