import React, { useState } from "react";
import { serveCardData, serveCardDataTwo } from "../../componenten/data";

const WhoWeServe = () => {
  const [selectedTab, setSelectedTab] = useState("client");

  return (
    <div className="mt-20 sm:mt-32 lg:mt-40 scroll-mt-8" id="serve">
      <h2 className="text-4xl text-center text-black sm:text-6xl font-pp">
        Wen helfen wir?
      </h2>

      <div className="w-full sm:w-[422px] h-[60px] sm:h-[70px] mx-auto block my-8">
        <div className="p-[1px] rounded-lg bg-homeTextGradient w-full h-full ">
          <div className="flex items-center w-full h-full bg-white rounded-lg">
            <button
              className={`${
                selectedTab === "client"
                  ? "bg-homeTextGradient text-white "
                  : "bg-transparent text-[#999999]"
              }  text-lg sm:text-xl w-full h-full rounded-lg`}
              onClick={() => setSelectedTab("client")}
            >
              Auftraggeber
            </button>
            <button
              className={`${
                selectedTab === "interpreters"
                  ? "bg-homeTextGradient text-white "
                  : "bg-transparent text-[#999999]"
              }  text-lg sm:text-xl w-full h-full rounded-lg`}
              onClick={() => setSelectedTab("interpreters")}
            >
              Dolmetscher
            </button>
          </div>
        </div>
      </div>

      {selectedTab === "client" ? (
        <div className="grid grid-cols-1 gap-2 mt-8 sm:gap-4 md:grid-cols-2">
          {serveCardData.map((item) => (
            <div
              className="relative flex flex-col gap-4 p-4 sm:p-8 bg-serveCardBg rounded-3xl "
              key={item.id}
            >
              <img
                src={item.BgIcon}
                alt="serveBgCardIcon1"
                className={`absolute top-0 right-0 max-sm:w-[140px] ${
                  item.id === 4 && "right-4"
                } `}
              />

              <p className="text-4xl sm:text-5xl">{item.textIcon}</p>
              <h1 className="text-xl text-transparent sm:text-2xl xl:text-3xl bg-homeTextGradient bg-clip-text font-pp">
                {item.header}
              </h1>
              <p className="text-[#999999] sm:text-lg xl:text-xl ">
                {item.text}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-2 mt-8 sm:gap-4 md:grid-cols-2">
          {serveCardDataTwo.map((item) => (
            <div
              className="relative flex flex-col gap-4 p-4 sm:p-8 bg-serveCardBg rounded-3xl "
              key={item.id}
            >
              <img
                src={item.BgIcon}
                alt="serveBgCardIcon1"
                className={`absolute top-0 right-0 max-sm:w-[140px] ${
                  item.id === 4 && "right-4"
                } `}
              />

              <p className="text-4xl sm:text-5xl">{item.textIcon}</p>
              <h1 className="text-xl text-transparent sm:text-2xl xl:text-3xl bg-homeTextGradient bg-clip-text font-pp">
                {item.header}
              </h1>
              <p className="text-[#999999] sm:text-lg xl:text-xl ">
                {item.text}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WhoWeServe;
