import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {createAuthHeader, fetchUrl, useFilter} from "../../utils/util";

export function NutzerAdmin() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchUsers() {
            try {
                const url = `${fetchUrl}/api/admin/users`;
                const response = await fetch(url,
                    {
                        headers: {
                            ...createAuthHeader(),
                        }
                    });

                if (response.ok) {
                    const data = await response.json();
                    setUsers(data);
                } else {
                    throw new Error('Fehler beim Laden der User: ' + response.status);
                }
            } catch (error) {
                console.error(error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }

        fetchUsers()
    }, []);

    const filterFunctions = {
        name: (user, value) => user.attributes.firstName.toLowerCase().includes(value.toLowerCase()) || user.attributes.lastName.toLowerCase().includes(value.toLowerCase()),
        email: (user, value) => user.attributes.email.toLowerCase().includes(value.toLowerCase())
    };

    const { filters, handleFilterChange, filteredItems: filteredUsers } = useFilter(users, filterFunctions);


    if (loading) {
        return <div>Laden...</div>;
    }

    if (error) {
        return <div>Fehler: {error}</div>;
    }


    return (
        <div className="container mx-auto mt-8 px-4">
            <h1 className="text-2xl font-bold mb-8 text-center">Alle Nutzer</h1>
            <div className="mb-8 flex flex-col md:flex-row items-center justify-center gap-2 md:gap-4">
                <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    value={filters.name || ''}
                    onChange={handleFilterChange}
                    className="border p-2 rounded w-full md:w-auto"
                />
                <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={filters.email || ''}
                    onChange={handleFilterChange}
                    className="border p-2 rounded w-full md:w-auto"
                />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {filteredUsers.map((user) =>
                    <div key={user.userID} className="border rounded-lg shadow-lg bg-white flex flex-col h-full">
                        <div className="p-6 flex-grow">
                            <h3 className="text-xl font-bold mb-4 text-center">{user.attributes.role} - {user.userID}</h3>
                            <p><strong>Vorname:</strong> {user.attributes.firstName}</p>
                            <p><strong>Nachname:</strong> {user.attributes.lastName}</p>
                            <p><strong>Mail:</strong> {user.attributes.email}</p>
                            <p><strong>Telefon:</strong> {user.attributes.phone}</p>
                            <p><strong>Status:</strong> {user.attributes.certified.toString().toUpperCase()}</p>
                            <p><strong>Mail
                                Benachrichtigung:</strong> {user.attributes.mailNotifications.toString().toUpperCase()}
                            </p>
                            <p><strong>Feedback:</strong> {user.attributes.countFeedbacks}</p>
                            <p><strong>Jobs:</strong> {user.attributes.countJobs}</p>
                        </div>
                        <button
                            onClick={() => navigate(`/dashboard-admin/nutzer/${user.userID}`)}
                            className="w-full bg-duoColours-2 text-duoColours-1 font-bold py-2 px-4 rounded-b-lg"
                        >
                            Nutzer einsehen
                        </button>
                    </div>
                )}

            </div>
        </div>
    );
}

