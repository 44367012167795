import {useState, useRef} from "react";
import {createAuthHeader, fetchUrl} from "../utils/util";
import Alert from "../Tools/Alert";

export default function Einladen() {
    const [invite, setInvite] = useState({
        userId: localStorage.getItem('userId'),
        email: ''
    });
    const [error, setError] = useState('');
    const [alertType, setAlertType] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');
    const alertRef = useRef();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        // E-Mail Validierung
        if (!invite.email.trim()) {
            setError('Bitte geben Sie eine E-Mail-Adresse ein.');
            setIsLoading(false);
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(invite.email)) {
            setError('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
            setIsLoading(false);
            return;
        }

        setError('');

        try {
            const response = await fetch(`${fetchUrl}/api/users/invite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...createAuthHeader()
                },
                body: JSON.stringify(invite)
            });

            if (response.ok) {
                setAlertMessage('Einladung erfolgreich gesendet.');
                setAlertType('info');
                setInvite({userId: localStorage.getItem('userId'), email: ''});
                alertRef.current?.triggerAlert();
            } else {
                const data = await response.json();
                setError(data.error || 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.');
            }
        } catch (error) {
            console.error('Einladungsfehler:', error);
            setError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal.');
        }
        setIsLoading(false);
    };

    return (
        <div className="container mx-auto p-4 md:p-10">
            <div className="bg-white shadow-lg rounded-lg p-6">
                <h1 className="text-3xl font-bold text-gray-700 mb-6 text-center">Kollegen einladen</h1>
                <p className="text-gray-600 text-lg mb-6">
                    Lade deine Kollegen ein, unserer Plattform beizutreten. Gib einfach ihre E-Mail-Adresse ein und sie
                    erhalten eine Einladung.
                </p>
                <form onSubmit={handleSubmit}>
                    <div className="mt-8">
                        <input
                            type="email"
                            className="p-2 border border-gray-300 rounded w-full focus:ring focus:ring-duoColours-2 focus:outline-none"
                            placeholder="E-Mail-Adresse des Kollegen"
                            value={invite.email}
                            onChange={(e) => setInvite({...invite, email: e.target.value})}
                        />
                        <button
                            type="submit"
                            className="w-full mt-4 bg-homeTextGradient text-white font-bold py-2 px-4 rounded transition-colors duration-300"
                            disabled={isLoading}

                        >
                            {isLoading ?
                                (
                                    <div className="flex items-center justify-center text-white">
                                        <div className="spinnerButton"></div>
                                    </div>
                                ) : (
                                    'Einladen'
                                )}

                        </button>
                        {error && (
                            <p className="text-red-500 text-sm mt-2">{error}</p>
                        )}
                        <Alert
                            message={alertMessage}
                            type={alertType}
                            reference={alertRef}
                            classname="w-full pt-2 z-50"
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}
