import { useState, useEffect } from 'react';

const useMediaDevices = () => {
    const [videoDevices, setVideoDevices] = useState([]);
    const [audioDevices, setAudioDevices] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState('');
    const [selectedMicrophone, setSelectedMicrophone] = useState('');
    const [deviceError, setDeviceError] = useState('');

    useEffect(() => {
        const fetchDevices = async () => {
            try {
                // Request permissions first
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                stream.getTracks().forEach((track) => track.stop()); // Stop the tracks as we just need permissions

                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoInputDevices = devices.filter((device) => device.kind === 'videoinput');
                const audioInputDevices = devices.filter((device) => device.kind === 'audioinput');
                setVideoDevices(videoInputDevices);
                setAudioDevices(audioInputDevices);

                if (!selectedCamera && videoInputDevices.length > 0) {
                    setSelectedCamera(videoInputDevices[0].deviceId);
                }
                if (!selectedMicrophone && audioInputDevices.length > 0) {
                    setSelectedMicrophone(audioInputDevices[0].deviceId);
                }
            } catch (error) {
                let message = 'Unable to access media devices.';
                if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
                    message = 'Permission denied. Please allow access to your camera and microphone.';
                }
                setDeviceError(message);
                console.error('Error fetching devices', error);
            }
        };

        fetchDevices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        videoDevices,
        audioDevices,
        selectedCamera,
        setSelectedCamera,
        selectedMicrophone,
        setSelectedMicrophone,
        deviceError,
    };
};

export default useMediaDevices;
