import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from './AuthProvider';

const PrivateRoute = ({allowedRoles}) => {
    const {currentUser, userRole, whitelisted, loading} = useAuth();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!currentUser || !allowedRoles.includes(userRole)) {
        return <Navigate to="/login" replace/>;
    }

    if (!whitelisted) {

        if (userRole === 'INTERPRETER') {
            return (
                <div className="homepage bg-blue-100 h-screen flex items-center">
                    <div className="container mx-auto p-4 md:p-10">
                        <div className="bg-white shadow-lg rounded-lg p-8">
                            <h1 className="text-3xl font-bold text-blue-800 mb-8 text-center">Willkommen bei DolmetschDirekt</h1>
                            <p className="text-gray-700 text-lg leading-relaxed mb-6">
                                Liebe Dolmetscherinnen und Dolmetscher,<br /><br />
                                wir freuen uns, dass Sie sich registriert und verifiziert haben. Bitte haben Sie Verständnis
                                dafür, dass Sie derzeit noch nicht auf alle Funktionen unserer Seite zugreifen
                                können.<br /><br />
                                Bevor Sie unsere Dienste in vollem Umfang nutzen können, möchten wir zunächst Ihre
                                Qualifikationen prüfen. Dies ist ein wichtiger Schritt, um sicherzustellen, dass unsere
                                hohen Standards eingehalten werden. Sobald diese Überprüfung abgeschlossen ist, setzen wir uns mit
                                Ihnen in Verbindung, um die Konditionen und eine entsprechende Vereinbarung zu
                                besprechen.<br /><br />
                                Wir danken Ihnen für Ihr Verständnis und Ihre Geduld. Bei Fragen stehen wir Ihnen jederzeit
                                zur Verfügung.<br /><br />
                                Mit freundlichen Grüßen,<br /><br />
                                Ihr Team von DolmetschDirekt
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
        if (userRole === 'CLIENT') {
            return (
                <div className="homepage bg-blue-100 h-screen flex items-center">
                    <div className="container mx-auto p-4 md:p-10">
                        <div className="bg-white shadow-lg rounded-lg p-8">
                            <h1 className="text-3xl font-bold text-blue-800 mb-8 text-center">Willkommen bei DolmetschDirekt</h1>
                            <p className="text-gray-700 text-lg leading-relaxed mb-6">
                                Liebe Kundinnen und Kunden,<br /><br />
                                wir freuen uns, dass Sie sich registriert und verifiziert haben. Bitte haben Sie Verständnis
                                dafür, dass Sie derzeit noch nicht auf alle Funktionen unserer Seite zugreifen
                                können.<br /><br />
                                Wir möchten Ihnen versichern, dass wir großen Wert auf Ihre Zufriedenheit legen. Mit unserem
                                Dienst wird Ihre Arbeit erheblich vereinfacht und zukünftige Projekte können mit weniger
                                Stress und Aufwand realisiert werden. Darüber hinaus bieten wir Ihnen die Möglichkeit, aktiv an der
                                Auswahl der Dolmetscher mitzuwirken, um sicherzustellen, dass Sie stets die bestmögliche
                                Unterstützung erhalten.<br /><br />
                                Um die nächsten Schritte zu besprechen und die Rahmenbedingungen für eine Zusammenarbeit
                                festzulegen, setzen wir uns zeitnah mit Ihnen in Verbindung.<br /><br />
                                Wir danken Ihnen für Ihr Vertrauen und Ihre Geduld. Bei Fragen stehen wir Ihnen jederzeit
                                zur Verfügung.<br /><br />
                                Mit freundlichen Grüßen,<br /><br />
                                Ihr Team von DolmetschDirekt
                            </p>
                        </div>
                    </div>
                </div>
            );
        }


    }

    return <Outlet/>;
};

export default PrivateRoute;
