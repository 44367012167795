import {
    HiOutlineViewGrid,
    HiOutlineShoppingCart,
    HiOutlineUsers,
    HiOutlineDocumentText,
    HiOutlineHome,
    HiOutlinePencilAlt,
    HiOutlineThumbUp,
    HiOutlineUserAdd,
    HiOutlineFolderOpen,
    HiOutlineClipboardCheck,
    HiOutlineChartBar,
    HiOutlineCalendar,
    HiOutlineLightBulb, HiViewList, HiUserCircle,
} from 'react-icons/hi'
import {FaChartBar} from "react-icons/fa";
import {HiChatBubbleLeftEllipsis} from "react-icons/hi2";

export const DASHBOARD_SIDEBAR_LINKS_C = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        path: '/dashboard-client',
        icon: <HiOutlineHome/>
    },
    {
        key: 'auftrag-anlegen',
        label: 'Auftrag anlegen',
        path: '/dashboard-client/auftraege-erstellen',
        icon: <HiOutlinePencilAlt/>
    },
    {
        key: 'auftraege-anzeigen',
        label: 'Aufträge anzeigen',
        path: '/dashboard-client/auftraege-anzeigen',
        icon: <HiViewList/>
    },
    {
        key: 'feedback',
        label: 'Feedback',
        path: '/dashboard-client/feedback',
        icon: <HiChatBubbleLeftEllipsis/>
    },
    {
        key: 'einladen',
        label: 'Einladen',
        path: '/dashboard-client/einladen',
        icon: <HiOutlineUserAdd/>
    }/*,
    {
        key: 'messages',
        label: 'Messages',
        path: '/messages',
        icon: <HiOutlineAnnotation />
    }*/
]

export const DASHBOARD_SIDEBAR_PLANNED_LINKS_C = [
    {
        key: 'erinnerungen',
        label: 'Erinnerungen',
        icon: <HiOutlineCalendar/>,
        comingSoon: true,
    },
    {
        key: 'auftragsbewertungen',
        label: 'Auftragsbewertungen',
        icon: <HiOutlineThumbUp/>,
        comingSoon: true,
    },
    {
        key: 'statistics',
        label: 'Statistiken',
        icon: <HiOutlineChartBar/>,
        comingSoon: true,
    },
    {
        key: 'ki-dolmetscher',
        label: 'KI Dolmetscher',
        icon: <HiOutlineLightBulb/>,
        comingSoon: true,
    },];


export const DASHBOARD_SIDEBAR_LINKS_I = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        path: '/dashboard-interpreter',
        icon: <HiOutlineHome/>
    },
    {
        key: 'meine-auftraege',
        label: 'Meine Aufträge',
        path: '/dashboard-interpreter/meine-auftraege',
        icon: <HiOutlineClipboardCheck/>
    },
    {
        key: 'auftraege-anzeigen',
        label: 'Aufträge Anzeigen',
        path: '/dashboard-interpreter/auftraege-anzeigen',
        icon: <HiOutlineFolderOpen/>
    },
    {
        key: 'feedback',
        label: 'Feedback',
        path: '/dashboard-interpreter/feedback',
        icon: <HiOutlineThumbUp/>
    },
    {
        key: 'einladen',
        label: 'Einladen',
        path: '/dashboard-interpreter/einladen',
        icon: <HiOutlineUserAdd/>
    }/*,
    {
        key: 'messages',
        label: 'Messages',
        path: '/messages',
        icon: <HiOutlineAnnotation />
    }*/
]

export const DASHBOARD_SIDEBAR_PLANNED_LINKS_I = [
    {
        key: 'erinnerungen',
        label: 'Erinnerungen',
        icon: <HiOutlineCalendar/>,
        comingSoon: true
    },
    {
        key:'statistiken',
        label: 'Statistiken',
        icon: <FaChartBar/>,
        comingSoon: true
    },
    {
        key: 'kalenderintegration',
        label: 'Kalenderintegration',
        icon: <HiOutlineCalendar/>,
        comingSoon: true
    },
];

export const DASHBOARD_SIDEBAR_LINKS_A = [
    // Dashboard, Nutzer, Aufträge, Berichte, Einstellungen
    {
        key: 'dashboard',
        label: 'Dashboard',
        path: '/dashboard-admin',
        icon: <HiOutlineViewGrid/>
    },
    {
        key: 'nutzer',
        label: 'Nutzer',
        path: '/dashboard-admin/nutzer',
        icon: <HiOutlineUsers/>
    },
    {
        key: 'auftraege',
        label: 'Aufträge',
        path: '/dashboard-admin/auftraege',
        icon: <HiOutlineShoppingCart/>
    },
    {
        key: 'feedback',
        label: 'Feedbacks',
        path: '/dashboard-admin/feedbacks',
        icon: <HiOutlineDocumentText/>
    },/*
    {
        key: 'einstellungen',
        label: 'Einstellungen',
        path: '/dashboard-admin/einstellungen',
        icon: <HiOutlineCog/>
    }*/
]


export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
    {
        key: 'profile',
        label: 'Profil',
        path: './profile',
        icon: <HiUserCircle/>
    },
    /*{
        key: 'settings',
        label: 'Settings',
        path: './settings',
        icon: <HiOutlineCog />
    },
    {
        key: 'support',
        label: 'Help & Support',
        path: './support',
        icon: <HiOutlineQuestionMarkCircle />
    }*/
]