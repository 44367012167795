import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../pages/footer";

export default function BaseLayout() {
    const location = useLocation();
    const pathname = location.pathname;

    return (
        <div className={` min-w-0`}>
            <div className="flex flex-col flex-1">
                {/* <Header /> */}
                <main className="flex-1 min-w-0">
                    <Outlet />
                </main>
                {/* <Footer/> */}
                <div
                    className={` ${
                        pathname === "/" ||
                        pathname === "/clients" ||
                        pathname === "/interpreters"
                            ? ""
                            : "hidden"
                    }`}
                >
                    <Footer />
                </div>
            </div>
        </div>
    );
}
