// A Alert Component that will be used to show alert messages you can input the message and the type of alert you want to show

import React, {useEffect, useImperativeHandle} from 'react';

/**
 * Alert Komponente für das Anzeigen von Benachrichtigungen auf der Benutzeroberfläche mit verschiedenen Typen
 * @param message - Nachricht, die angezeigt werden soll
 * @param type - Typ der Benachrichtigung (warning, error, success, info)) - Info = blau, Success = grün, Error = rot, Warning = gelb
 * @param reference - Referenz, um die Benachrichtigung von außen auszulösen
 * @param classname - Klassenname für die Benachrichtigung um Tailwind zu supporten
 */
export default function Alert({message, type, reference, classname}) {
    const [isAlertVisible, setIsAlertVisible] = React.useState(false);

    const alertColors = {
        warning: {
            border: "text-yellow-800 border-t-4 border-yellow-300 bg-yellow-50 dark:text-yellow-300 dark:border-yellow-800",
            background: "bg-yellow-50 text-yellow-500 focus:ring-yellow-400 hover:bg-yellow-200 dark:text-yellow-300"
        },
        error: {
            border: "text-red-800 border-t-4 border-red-300 dark:text-red-400 dark:border-red-800",
            background: "bg-red-50 text-red-500 focus:ring-red-400 hover:bg-red-200 dark:text-red-400"
        },
        success: {
            border: "text-green-800 border-t-4 border-green-300 bg-green-50 dark:text-green-400 dark:border-green-800",
            background: "bg-green-50 text-green-500 focus:ring-green-400 hover:bg-green-200 dark:text-green-400"
        },
        info: {
            border: "text-blue-800 border-t-4 border-blue-300 bg-blue-50 dark:text-blue-400 dark:border-blue-800",
            background: "bg-blue-50 text-blue-500 focus:ring-blue-400 hover:bg-blue-200 dark:text-blue-400"
        }
    };

    // Übergeben der Funktion zum Auslösen des Alerts an die übergeordnete Komponente
    useImperativeHandle(reference, () => ({
        triggerAlert(){
            setIsAlertVisible(true);
            setTimeout(() => {
                disappearAlert();
            }, 5000);
        }
    }), []);

    useEffect(() => {
        if (isAlertVisible) {
            document.getElementById('alert').style.display = 'block';
        }else {
            setTimeout(() => {
                document.getElementById('alert').style.display = 'none';
            }, 750);
        }
    }, [isAlertVisible]);

    // Standardfarbe für den Fall, dass der Typ nicht definiert ist
    const alertColor = alertColors[type] || alertColors.info;

    function disappearAlert() {
        setIsAlertVisible(false);
    }

    return (
        <div id="alert" className={`${classname} pointer-events-auto`}>
            <div
                className={`${alertColor.border} ${isAlertVisible ? '' : 'opacity-0 transition-all duration-[500ms] ease-in-out'} 
             rounded flex items-center p-4 border-t-4 bg-white-bg`}
                role="alert">
                <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                     fill="currentColor" viewBox="0 0 20 20">
                    <path
                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>
                <div className="ms-3 text-sm font-medium">
                    {message}
                </div>
                <button type="button"
                    // if the button is hovered, the background color will change and a small border will appear
                        className={`${alertColor.background} ms-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex items-center justify-center h-8 w-8 bg-white-bg dark:hover:bg-white`}
                        data-dismiss-target="#alert-border-1" aria-label="Close"
                        onClick={disappearAlert}>
                    <span className="sr-only">Dismiss</span>
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                </button>
            </div>
        </div>
    );
}
