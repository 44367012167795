import React, {useState, useEffect} from "react";
import {createAuthHeader, fetchUrl, toggleMailNotification} from "../../utils/util";
import {AiOutlineMail, AiOutlinePhone, AiOutlineTeam, AiOutlineUser} from "react-icons/ai";

export default function ProfileClient() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [profile, setProfile] = useState({
        firstName: '',
        lastName: '',
        email: '',
        gender: '',
        phone: '',
        companyName: '',
        mailNotification: true,
        contactPerson: '',
    });

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        if (!userId) {
            console.error('Benutzer-ID nicht gefunden.');
            setError('Benutzer-ID nicht gefunden.')
            setLoading(false);
            return;
        }

        const fetchData = async () => {
            try {
                const response = await fetch(`${fetchUrl}/api/users/client/${userId}`, {
                    headers: {
                        ...createAuthHeader(),
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setProfile(data);
                } else {
                    console.error("Fehler beim Abrufen des Profils");
                    setError("Fehler beim Abrufen des Profils");
                }
            } catch (error) {
                console.error("Fehler beim Abrufen des Profils: ", error);
                setError(error.message);

            }
        };

        fetchData().finally(() => setLoading(false));
    }, []);


    if (loading) {
        return <div className="text-center mt-10">Laden...</div>;
    }

    if (error) {
        return <div className="text-center mt-10 text-red-500">Fehler: {error}</div>;
    }

    return (
        <div className="container mx-auto p-6">
            <div className="rounded-lg overflow-hidden">
                <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">Mein Profil</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="bg-gray-50 shadow-lg border rounded-lg p-6">
                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Persönliche Informationen</h2>
                        <div className="flex items-center mb-4">
                            <AiOutlineUser className="text-2xl text-blue-600 mr-3"/>
                            <p className="text-lg"><strong>Name:</strong> {profile.firstName} {profile.lastName}</p>
                        </div>
                        <div className="flex items-center mb-4">
                            <AiOutlineMail className="text-2xl text-blue-600 mr-3"/>
                            <p className="text-lg"><strong>Email:</strong> {profile.email}</p>
                        </div>
                        <div className="flex items-center mb-4">
                            <AiOutlinePhone className="text-2xl text-blue-600 mr-3"/>
                            <p className="text-lg"><strong>Telefon:</strong> {profile.phone}</p>
                        </div>
                        <div className="flex items-center mb-4">
                            <p className="text-lg"><strong>Geschlecht:</strong> {profile.gender}</p>
                        </div>
                        <div className="mt-4">
                            <button onClick={toggleMailNotification}
                                    className={`w-full py-2 px-4 rounded-lg font-bold ${profile.mailNotification ? 'bg-homeTextGradient text-white' : 'bg-homeTextGradient text-white'}`}>
                                {profile.mailNotification ? 'Mail-Benachrichtigungen deaktivieren' : 'Mail-Benachrichtigungen aktivieren'}
                            </button>
                        </div>
                    </div>
                    <div className="bg-gray-50 shadow-lg border rounded-lg p-6">
                        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Firmeninformationen</h2>
                        <div className="flex items-center mb-4">
                            <AiOutlineTeam className="text-2xl text-blue-600 mr-3"/>
                            <p className="text-lg"><strong>Firmenname:</strong> {profile.companyName}</p>
                        </div>
                        {/*<p><strong>Angegebener Kontakt:</strong> {profile.contactPerson}</p>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}
