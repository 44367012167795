import React from 'react';

const DebugLog = ({ log, toggleLogEntry }) => {
    return (
        <div className="w-full lg:w-1/4 p-4 bg-gray-100 overflow-auto">
            <h3 className="text-lg font-bold">Debug Log</h3>
            <ul className="text-sm space-y-2">
                {log.map((entry, index) => (
                    <li key={index} className="p-2 border-b">
                        <div
                            className="flex justify-between items-center cursor-pointer"
                            onClick={() => toggleLogEntry(index)}
                        >
                            <span className="text-xs text-gray-500">{entry.timestamp}</span>
                            <span className="ml-2 font-semibold">{entry.title}</span>
                            <span className="ml-auto">{entry.expanded ? '-' : '+'}</span>
                        </div>
                        {entry.expanded && (
                            <div className="mt-2 bg-gray-200 p-2 rounded">
                <pre className="text-xs whitespace-pre-wrap break-words overflow-auto">
                  {JSON.stringify(entry.details, null, 2)}
                </pre>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DebugLog;
