import React, {useEffect, useRef, useState} from 'react';
import placeholderImage from '../../assets/placeholder-video.jpg';

const PreCallTest = ({
                         videoDevices,
                         audioDevices,
                         selectedCamera,
                         setSelectedCamera,
                         selectedMicrophone,
                         setSelectedMicrophone,
                         proceedToCall,
                     }) => {
    const [testStream, setTestStream] = useState(null);
    const videoRef = useRef();
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const [audioLevel, setAudioLevel] = useState(0);
    const [cameraError, setCameraError] = useState('');
    const [microphoneError, setMicrophoneError] = useState('');
    const [currentStep, setCurrentStep] = useState(1);

    // Initialisiere den Kameratest
    const startCameraTest = async () => {
        setCameraError('');
        try {
            const stream = await navigator.mediaDevices.getUserMedia({video: selectedCamera ? {deviceId: {exact: selectedCamera}} : true});
            setTestStream(stream);
            if (videoRef.current) videoRef.current.srcObject = stream;
        } catch (error) {
            handleCameraError(error);
        }
    };

    const handleCameraError = (error) => {
        const text = " Sie können zum nächsten Schritt fortfahren, wir verwenden dann entweder die Standardkamera oder ein Platzhalter."
        const errorMessages = {
            NotFoundError: 'Keine Kamera gefunden. ' + text,
            NotAllowedError: 'Kamerazugriff verweigert.' + text,
            NotReadableError: 'Kamera wird von einem anderen Prozess verwendet.' + text,
            default: 'Kamerafehler aufgetreten.' + text,
        };
        setCameraError(errorMessages[error.name] || errorMessages.default);
    };

    const retryCameraTest = () => {
        setCameraError('');
        stopStream();
        startCameraTest();
    };

    // Initialisiere den Mikrofontest
    const startMicrophoneTest = async () => {
        setMicrophoneError('');
        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: selectedMicrophone ? {deviceId: {exact: selectedMicrophone}} : true});
            setupAudioVisualization(stream);
        } catch (error) {
            handleMicrophoneError(error);
        }
    };

    const handleMicrophoneError = (error) => {
        const errorMessages = {
            NotFoundError: 'Kein Mikrofon gefunden.',
            NotAllowedError: 'Mikrofonzugriff verweigert.',
            NotReadableError: 'Mikrofon wird von einem anderen Prozess verwendet.',
            default: 'Mikrofonfehler aufgetreten.',
        };
        setMicrophoneError(errorMessages[error.name] || errorMessages.default);
    };

    const retryMicrophoneTest = () => {
        setMicrophoneError('');
        stopStream();
        startMicrophoneTest();
    };

    const setupAudioVisualization = (stream) => {
        if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
            audioContextRef.current.close();
        }
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContextRef.current.createMediaStreamSource(stream);
        analyserRef.current = audioContextRef.current.createAnalyser();
        source.connect(analyserRef.current);
        analyserRef.current.fftSize = 256;
        visualizeAudio();
    };

    const visualizeAudio = () => {
        if (!analyserRef.current) return;
        const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
        const update = () => {
            analyserRef.current.getByteFrequencyData(dataArray);
            const volume = dataArray.reduce((a, b) => a + b) / dataArray.length;
            setAudioLevel(volume);
            requestAnimationFrame(update);
        };
        update();
    };

    const stopStream = () => {
        if (testStream) {
            testStream.getTracks().forEach((track) => track.stop());
            setTestStream(null);
        }
        if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
            audioContextRef.current.close();
        }
    };

    useEffect(() => {
        if (currentStep === 1) startCameraTest();
        else if (currentStep === 2) startMicrophoneTest();
        return () => stopStream();
    }, [currentStep, selectedCamera, selectedMicrophone]);

    const handleNextStep = () => setCurrentStep((prevStep) => prevStep + 1);

    return (
        <div className="flex flex-col items-center justify-center w-full max-w-lg mx-auto p-5">
            <h2 className="text-center text-2xl font-semibold mb-5">Bereiten Sie sich auf Ihren Anruf vor</h2>

            {currentStep === 1 && (
                <>
                    <p className="text-center text-xl mb-2"><strong>Schritt 1: Kameratest</strong></p>
                    <p className="text-center text-base mb-5">Bitte wählen Sie die Kamera aus, die Sie für den Anruf verwenden möchten.</p>

                    <label className="w-full mb-5">
                        <select onChange={(e) => setSelectedCamera(e.target.value)} value={selectedCamera} className="w-full p-2 border rounded-md">
                            {videoDevices.map((device) => (
                                <option key={device.deviceId} value={device.deviceId}>{device.label || `Kamera ${device.deviceId.substr(0, 5)}`}</option>
                            ))}
                        </select>
                    </label>

                    <div className="w-full h-72 bg-black rounded-lg overflow-hidden mb-5 flex items-center justify-center">
                        {testStream && testStream.getVideoTracks().length > 0 ? (
                            <video ref={videoRef} autoPlay playsInline muted className="w-full h-full object-cover" />
                        ) : (
                            <img src={placeholderImage} alt="Placeholder" className="w-full h-full object-contain" />
                        )}
                    </div>

                    {cameraError && (
                        <div className="w-full bg-red-100 text-red-700 p-4 rounded-lg mb-5">
                            <p>{cameraError}</p>
                            <button onClick={retryCameraTest} className="w-full p-3 bg-yellow-500 text-white rounded-md">Erneut versuchen</button>
                        </div>
                    )}

                    <button onClick={handleNextStep} className="w-full p-3 bg-homeTextGradient text-white rounded-md">Nächster Schritt: Mikrofon-Test</button>
                </>
            )}

            {currentStep === 2 && (
                <>
                    <p className="text-center text-xl mb-2"><strong>Schritt 2: Mikrofontest</strong></p>
                    <p className="text-center text-base mb-5">Bitte wählen Sie das Mikrofon aus, das Sie verwenden
                        möchten.</p>

                    <label className="w-full mb-5">
                        <select onChange={(e) => setSelectedMicrophone(e.target.value)} value={selectedMicrophone}
                                className="w-full p-2 border rounded-md">
                            {audioDevices.map((device) => (
                                <option key={device.deviceId}
                                        value={device.deviceId}>{device.label || `Mikrofon ${device.deviceId.substr(0, 5)}`}</option>
                            ))}
                        </select>
                    </label>

                    <div className="w-full h-5 bg-gray-200 rounded-full overflow-hidden mb-3">
                        <div className="h-full bg-green-500" style={{width: `${(audioLevel / 255) * 100}%`}}></div>
                    </div>

                    {microphoneError && (
                        <div className="w-full bg-red-100 text-red-700 p-4 rounded-lg mb-5">
                            <p>{microphoneError}</p>
                            <button onClick={retryMicrophoneTest}
                                    className="w-full p-3 bg-yellow-500 text-white rounded-md">Erneut versuchen
                            </button>
                        </div>
                    )}
                    <button onClick={proceedToCall} className="w-full p-3 bg-homeTextGradient text-white rounded-md">
                        Weiter zum Anruf
                    </button>
                </>
            )}
        </div>
    );
};
export default PreCallTest;
