import React from "react";
import {logo} from "../assets";
import {IoMdMail} from "react-icons/io";
import {FaPhone} from "react-icons/fa6";

const Footer = () => {
    const currentYear = new Date().getFullYear();

    const openMailClient = () => {
        window.location.href = "mailto:info@dolmetschdirekt.de";
    };

    const openPhoneClient = () => {
        window.location.href = "tel:+4917621604879";
    }

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: "smooth"});
        }
    };

    return (
        <div className="container mt-20 sm:mt-32 lg:mt-40">
            <div
                className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-5 sm:gap-y-8 2xl:gap-y-0 2xl:grid-cols-4 ">
                <div className="px-3 sm:px-6 py-6 rounded-lg bg-homeTextGradient w-full sm:w-[524px]">
                    <img src={logo} alt="Logo" className="w-[140px]"/>

                    <p className="mt-4 text-lg text-white sm:text-xl">
                        Wir bieten eine seriöse Plattform für Dolmetscher und Auftraggeber,
                        die Dolmetsch- oder Übersetzungsleistungen beauftragen möchten
                    </p>
                </div>

                <div className="flex flex-col flex-1 md:items-end">
                    <div className="flex flex-col gap-4">
                        <p
                            className="text-lg text-black cursor-pointer sm:text-xl w-fit text-start"
                            onClick={() => scrollToSection("work")}
                        >
                            So funktioniert es
                        </p>
                        <p
                            className="text-lg text-black cursor-pointer sm:text-xl w-fit text-start"
                            onClick={() => scrollToSection("features")}
                        >
                            Besonderheit
                        </p>
                        <p
                            className="text-lg text-black cursor-pointer sm:text-xl w-fit text-start"
                            onClick={() => scrollToSection("serve")}
                        >
                            Wen helfen wir
                        </p>
                    </div>
                </div>

                <div className="flex flex-col flex-1 xl:items-end">
                    <div className="flex flex-col gap-4">
                        <a href="/impressum">
                            <p className="text-lg text-black cursor-pointer sm:text-xl w-fit text-start">
                                Impressum
                            </p>
                        </a>
                        {/*<a href="/datenschutz">
                            <p className="text-lg text-black cursor-pointer sm:text-xl w-fit text-start">
                                Datenschutz
                            </p>
                        </a>*/}
                    </div>
                </div>

                <div className="flex flex-row gap-2 sm:flex-col max-md:items-start max-xl:items-end 2xl:items-end">
                    <div
                        className="flex items-center justify-center rounded-lg cursor-pointer size-14 bg-homeTextGradient "
                        onClick={() => openMailClient()}
                    >
                        <IoMdMail className="text-3xl text-white"/>
                    </div>
                    <div
                        className="flex items-center justify-center rounded-lg cursor-pointer size-14 bg-homeTextGradient "
                        onClick={() => openPhoneClient()}>
                        <FaPhone className="text-3xl text-white"/>

                    </div>
                </div>
            </div>
            {" "}
            <p className="my-8 text-sm text-black text-opacity-30">
                DOLMETSCHDIREKT © {currentYear}
            </p>
        </div>
    );
};

export default Footer;
