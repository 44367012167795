import React, { useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import { fetchUrl, validatePassword  } from "../utils/util";
import Navbar from "../componenten/shared/navbar";

function ResetPassword() {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [keyParams] = useSearchParams();
    const token = keyParams.get("key")?.replace("key=", "").replaceAll(" ", "+") || "";

    console.log("token: ", token);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");

        if (newPassword !== confirmPassword) {
            setMessage("Die Passwörter stimmen nicht überein.");
            return;
        }

        if (!validatePassword(newPassword, setMessage)) {
            return;
        }

        setIsLoading(true);
        try {
            const response = await fetch(fetchUrl + "/api/auth/reset-password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token,
                    newPassword
                }),
            });

            if (response.ok) {
                setMessage("Passwort erfolgreich zurückgesetzt.");
                setTimeout(() => navigate("/login"), 2000);
            } else {
                const error = await response.json();
                setMessage(error.message || "Ein Fehler ist aufgetreten.");
            }
        } catch (error) {
            setMessage("Ein Fehler ist aufgetreten. Bitte versuche es später erneut.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <div className="flex items-center justify-center p-4" style={{minHeight: 'calc(100vh - 6rem)'}}>
                <div className="w-full max-w-md p-8 bg-white border border-black rounded-lg shadow-lg border-opacity-20">
                    <h2 className="mb-6 text-2xl font-bold text-center">Neues Passwort festlegen</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                                Neues Passwort
                            </label>
                            <input
                                type="password"
                                id="newPassword"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md"
                                maxLength="50"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                Passwort bestätigen
                            </label>
                            <input
                                type="password"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                        {message && (
                            <p className={`text-sm ${message.includes("erfolgreich") ? "text-green-600" : "text-red-600"}`}>
                                {message}
                            </p>
                        )}
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="w-full px-4 py-2 font-bold text-white rounded bg-homeTextGradient"
                        >
                            {isLoading ? "Wird verarbeitet..." : "Passwort zurücksetzen"}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
