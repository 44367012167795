import React from "react";
import { deviceImage, globleIcon, instantImg, privacyIcon } from "../../assets";

const VideoInterpreting = () => {
    return (
        <div className="container pt-12 lg:py-24">
            <h1 className="text-4xl text-center text-black md:text-5xl font-pp">
                Video-Dolmetschen
            </h1>
            <p className="text-[#999999] text-lg sm:text-xl text-center mt-4">
                Für medizinisches Fachpersonal, Pflegekräfte, Psychologen und
                öffentliche Einrichtungen
            </p>

            <div className="flex flex-col gap-4 mt-8 xl:flex-row">
                <div className="flex flex-col gap-4 w-full xl:w-[494px]">
                    <div className="flex items-end gap-2 p-2 sm:p-4 sm:gap-4 max-xl:flex-1 bg-worksGradient2 rounded-xl backdrop-blur-xl">
                        <div className="flex-shrink-0">
                            <img
                                src={deviceImage}
                                alt="Device Image"
                                className="size-10 sm:size-12"
                            />
                        </div>

                        <div className="flex flex-col gap-4 ">
                            <p className="text-[#999]  font-pp text-end">01/</p>
                            <p className="text-black text-end ">
                                Egal ob Smartphone, Tablet oder Computer – unsere
                                Video-Dolmetschdienste funktionieren nahtlos auf allen Geräten.
                                So können Sie unabhängig von der Technik direkt und
                                unkompliziert kommunizieren.
                            </p>

                            <h2 className="mb-2 text-xl text-transparent sm:mb-2 sm:text-2xl bg-homeTextGradient bg-clip-text font-pp">
                                Kompatibel auf jedem Gerät
                            </h2>
                        </div>
                    </div>

                    <div className="flex items-end gap-2 p-2 sm:p-4 sm:gap-4 max-xl:flex-1 bg-worksGradient2 rounded-xl backdrop-blur-xl">
                        <div className="flex-shrink-0 max-sm:pb-2 xl:pb-2">
                            <img
                                src={instantImg}
                                alt="Instant Image"
                                className="size-10 sm:size-12"
                            />
                        </div>

                        <div className="flex flex-col gap-4 ">
                            <p className="text-[#999]  font-pp text-end">02/</p>
                            <p className="text-black text-end">
                                Mit unserem Video-Dolmetschservice sparen Sie Zeit und Geld. Mit
                                nur wenigen Klicks verbinden Sie sich in Echtzeit mit
                                qualifizierten Dolmetschern und können sofort loslegen. Keine
                                aufwändigen Terminvereinbarungen, keine Wartezeiten – einfach
                                und effizient.
                            </p>

                            <h2 className="text-xl text-transparent max-sm:mb-0 max-xl:mb-2 sm:text-2xl bg-homeTextGradient bg-clip-text font-pp">
                                Schnelle und einfache Kommunikation
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col flex-1 gap-4 lg:flex-row">
                    <div className="flex flex-col justify-between p-4 bg-worksGradient2 rounded-xl backdrop-blur-xl">
                        <div className="flex-col flex-1 gap-4 max-xl:flex">
                            <p className="text-[#999]  font-pp text-end">03/</p>
                            <p className="text-black text-end">
                                Ihre Daten sind bei uns sicher. Unser Video-Dolmetschsystem
                                erfüllt die höchsten Sicherheitsstandards und ist vollständig
                                DSGVO-konform. Sie können sich darauf verlassen, dass
                                vertrauliche Informationen geschützt sind.
                            </p>
                        </div>

                        <div className="flex items-center gap-4 max-xl:mt-4">
                            <img
                                src={privacyIcon}
                                alt="Privacy Image"
                                className="flex-shrink-0 size-10 sm:size-12"
                            />

                            <h2 className="text-2xl text-transparent bg-homeTextGradient bg-clip-text font-pp">
                                Schnelle und einfache Kommunikation
                            </h2>
                        </div>
                    </div>

                    <div className="flex flex-col justify-between p-4 bg-worksGradient2 rounded-xl backdrop-blur-xl">
                        <div className="flex-col flex-1 gap-4 max-xl:flex">
                            <p className="text-[#999]  font-pp text-end">04/</p>
                            <p className="text-black text-end">
                                Ob im Büro, in der Klinik oder unterwegs – unsere Dolmetscher
                                sind jederzeit für Sie verfügbar. Unabhängig vom Standort
                                ermöglichen wir Ihnen eine schnelle und reibungslose
                                Kommunikation, egal wo sich Ihre Gesprächspartner befinden.
                            </p>
                        </div>

                        <div className="flex items-center gap-4 max-xl:mt-4">
                            <img
                                src={globleIcon}
                                alt="Globle Image"
                                className="flex-shrink-0 size-10 sm:size-12"
                            />

                            <h2 className="text-2xl text-transparent bg-homeTextGradient bg-clip-text font-pp">
                                Ortsunabhängige Verfügbarkeit
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoInterpreting;
