import React, {useState} from 'react';
import {AnimatePresence, motion} from "framer-motion";
import {LinksOverlay} from "../../componenten/shared/drawer";
import Navbar from "../../componenten/shared/navbar";

function Impressum() {
    return (

            <div className="">
                <Navbar/>
                <div className="h-screen mt-16 md:mt-24 w-full max-w-4xl mx-auto p-6">
                    <h1 className="text-3xl font-bold text-blue-900 mb-6">Impressum</h1>

                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Diensteanbieter</h2>
                        <p className="text-lg text-gray-700">Hovsep Aroyan</p>
                    </div>

                    {/*<div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Anschrift</h2>
                        <p className="text-lg text-gray-700">
                            Straße und Hausnummer:<br/>
                            Postleitzahl und der Ort:
                        </p>
                    </div>*/}

                    <div className="mb-6">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-2">Kontaktmöglichkeiten</h2>
                        <p className="text-lg text-gray-700">
                            E-Mail-Adresse: <a href="mailto:hovsep.aroyan@outlook.de"
                                               className="text-blue-600 hover:underline">info (at)
                            dolmetschdirekt.de</a>
                        </p>
                    </div>
                </div>
            </div>

    );
}

export default Impressum;
